@import "../../../less/Variables.less";
@import "../../../less/Fonts";
@import "../../../less/Mixins";

/* Weekpicker styling */

.week-picker {
  .rdp {
        --rdp-cell-size: 30px;
        --rdp-outline: 2px solid @gunmetal;
   }

  .dialog-sheet {
    background: white;

    .rdp-caption_label {
        .font16MediumSteel();
    }

    .rdp-head_cell {
        .font12LightSteel();
    }

    .rdp-weeknumber {
        .font12MediumSteel();

        &:after {
            content:"";
            position:absolute;
            width: 30px;
            height: 30px;
            border-right: 1px solid lighten(@steel,35%);
        }
    }

    .rdp-day {
        .font12LightSteel();
    }

    .rdp-day_selected {
        font-weight: 500;
        color: black;
        background-color: @silver;

        &:focus-visible {
            outline: none;
        }
    }

    .rdp-day_today {
        color: @darkSkyBlue;
    }
  }
}
