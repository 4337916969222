@import "../../../less/Variables";

/* InfoContainer Styling*/

.infotext-container {
    background-color: @infoBlue;
    padding: 7px;

    i {
        margin-right: 5px;
        color: @infoIconBlue;
    }

    label {
        .font12MediumGunMetal()
    }
}