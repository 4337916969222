/* DigitalSigningOverviewModal Styling*/

.digital-signing-overview-modal {
  .noSelect();
  min-width: 1235px;

  .overflow-ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .digital-signing-overview-modal__header {
    .font14RegularWhite();
    width: 100%;
    display: flex;
    line-height: 32px;
    height: 32px;
    background-color: @darkSkyBlue;
    font-weight: 500;
    text-transform: uppercase;

    .digital-signing-overview-modal__header__tscode {
      padding: 0 5px;
      width: 260px;
    }

    .digital-signing-overview-modal__header__status {
      padding: 0 5px;
      width: 175px;
    }

    .digital-signing-overview-modal__header__declinereason {
      padding: 0 5px;
      width: 260px;
    }

    .digital-signing-overview-modal__header__statuschangeddate {
      padding: 0 5px;
      width: 135px;
    }

    .digital-signing-overview-modal__header__documentname {
      padding: 0 5px;
      width: 280px;
    }

    .digital-signing-overview-modal__header__actions {
      padding: 0 5px;
      width: 85px;
    }
  }

  .digital-signing-overview-modal__item__container {
    height: 400px;
    overflow: auto;
    .styled-scrollbar();

    .digital-signing-overview-modal__item {
      .font12RegularSteel();
      width: 100%;
      display: flex;
      line-height: 32px;
      height: 32px;

      &:nth-child(even) {
        background-color: @paleGrey;
      }

      .digital-signing-overview-modal__item__tscode {
        .digital-signing-overview-modal__item__tscode--overflow {
          padding: 0 5px;
          width: 260px;
          .overflow-ellipsis();
        }
      }

      .digital-signing-overview-modal__item__status {
        padding: 0 5px;
        width: 175px;
      }

      .digital-signing-overview-modal__item__declinereason {
        .digital-signing-overview-modal__item__declinereason--overflow {
          padding: 0 5px;
          width: 260px;
          .overflow-ellipsis();
        }
      }

      .digital-signing-overview-modal__item__statuschangeddate {
        padding: 0 5px;
        width: 135px;
      }

      .digital-signing-overview-modal__item__actions {
        width: 85px;

        .fa {
          width: 25px;
          line-height: 25px;
          text-align: center;

          &:hover {
            cursor: pointer;
            color: darken(@steel, 25%);
          }
        }
      }


      .tooltip(@textContent) {
        position: relative;
        opacity: 0.9;

        &:hover {
          text-decoration: none;

          &:after {
            content: @textContent;
            background: @charcoalGrey;
            white-space: nowrap;
            border-radius: 4px;
            height: 28px;
            line-height: 28px;
            padding: 0 10px;
            z-index: 98;
            margin-left: 14px;
            .font14RegularWhite();

            position: absolute;
          }
        }
      }

      .fa-trash {
        visibility: hidden;
        .tooltip('Cancel the signing process.');

        &:after {
          left: -240px;
        }

        &[data-cancancel] {
          visibility: visible;
        }
      }

      .fa-download {
        visibility: hidden;

        &[data-hasdocumentid] {
          visibility: visible;
        }

        .tooltip('Download the signed timesheet.');
        &:after {
          left: -245px;
        }
      }

      .digital-signing-overview-modal__item__icon {
        line-height: 32px;
        padding-left: 5px;
        width: 18px;
        opacity: 0.8;

        &:hover {
          cursor: pointer;
          color: darken(@steel, 25%);

          .digital-signing-overview-modal__item__icon {
            color: @steel;
            opacity: 1;
          }
        }
      }

      .digital-signing-overview-modal__item__actions {
        width: 85px;

        .fa {
          width: 25px;
          line-height: 25px;
          text-align: center;

          &:hover {
            cursor: pointer;
            color: @darkSkyBlue;
          }
        }
      }


      .tooltip(@textContent) {
        position: relative;
        opacity: 0.9;

        &:hover {
          text-decoration: none;

          &:after {
            content: @textContent;
            background: @charcoalGrey;
            white-space: nowrap;
            border-radius: 4px;
            height: 28px;
            line-height: 28px;
            padding: 0 10px;
            z-index: 98;
            margin-left: 14px;
            .font14RegularWhite();

            position: absolute;
          }
        }
      }

      .fa-trash {
        visibility: hidden;
        .tooltip('Cancel the signing process.');

        &:after {
          left: -240px;
        }

        &[data-cancancel] {
          visibility: visible;
        }

        &[data-showdocumentName] {
          visibility: visible;
        }
      }

      .fa-download {
        visibility: hidden;

        &[data-hasdocumentid] {
          visibility: visible;
        }

        .tooltip('Download the signed timesheet.');
        &:after {
          left: -245px;
        }
      }

      .digital-signing-overview-modal__item__icon {
        line-height: 32px;
        padding-left: 5px;
        width: 18px;
        opacity: 0.8;

        &:hover {
          cursor: pointer;
          color: darken(@steel, 25%);

          .digital-signing-overview-modal__item__icon {
            color: @steel;
            opacity: 1;
          }
        }
      }

      .digital-signing-overview-modal__item__documentname {
        padding: 0 5px;
        width: 280px;

        &[data-hasdocumentid] {
          cursor: pointer;
          display: flex;

          .digital-signing-overview-modal__item__documentname__name {
            width: 257px;
            .overflow-ellipsis();
          }
        }
      }
    }

    .digital-signing-overview-modal__no-items {
      .font12RegularSteel();
      line-height: 32px;
      height: 32px;
      padding-left: 5px;
    }

    .tooltip-data-content-tscode[data-content] {
      display: block;
      position: relative;
      opacity: 0.9;

      &:hover {
        text-decoration: none;
        z-index: 99;

        &:after {
          content: attr(data-content);
          display: block;
          .font12RegularWhite();
          background: @charcoalGrey;
          border-radius: 4px;
          line-height: 28px;
          padding: 0 10px;
          width: 240px;
          max-width: 240px;
          word-wrap: break-word;

          left: 0px;
          top: 13px;
          position: absolute;
          z-index: 98;
        }
      }
    }

    .tooltip-data-content-declinereason[data-content] {
      display: block;
      position: relative;
      opacity: 0.9;

      &:hover {
        text-decoration: none;
        z-index: 99;

        &:after {
          content: attr(data-content);
          display: block;
          .font12RegularWhite();
          background: @charcoalGrey;
          border-radius: 4px;
          line-height: 28px;
          padding: 0 10px;
          width: 250px;
          max-width: 250px;
          word-wrap: break-word;

          left: -20px;
          top: 13px;
          position: absolute;
          z-index: 98;
        }
      }
    }
  }
}