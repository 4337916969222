@import "../../../../less/Variables";
@import "../../../../less/Mixins";


/* TimesheetCodeDayAddHoursComment styling */

.timesheet-code-day-comment-mandatory {
  .noSelect();
  position: absolute;
  top: 71px;
  left: ~"calc(51% - 218px)";
  z-index: 100;
  padding: 20px;
  min-width: 436px;
  background-color: @white;
  border-radius: 3px;
  border: solid 1px @silverTwo;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);

  .resize-polyfill-wrapper {
    min-height: @commentAreaHeight !important;
    min-width: 406px !important;
    max-width: 406px !important;

    textarea {
      min-height: @commentAreaHeight !important;
      height: 100%;
    }
  }

  .form-group {
    textarea {
      resize: vertical;
      min-height: @commentAreaHeight;
      min-width: 300px;
    }
  }

  &.inverted {
    top: -394px;

    &::before {
      content: '';
      position: absolute;
      top: 386px;
      left: 211px;
      border: 6px solid transparent;
      border-top-color: darken(@silverTwo, 5%);
    }

    &::after {
      content: '';
      position: absolute;
      top: 385px;
      left: 211px;
      border: 6px solid transparent;
      border-top-color: @white;
    }

    &.level2 {
      top: -394px + (@timesheetRowHeight /2) * 2;
    }
  
    &.level3 {
      top: -394px + (@timesheetRowHeight /2) * 3;
    }
  
    &.level4 {
      top: -394px + (@timesheetRowHeight /2) * 4;
    }
  
    &.level5 {
      top: -394px + (@timesheetRowHeight /2) * 5;
    }
  }

  &.level2 {
    top: 71px + (@timesheetRowHeight /2);
  }

  &.level3 {
    top: 71px + (@timesheetRowHeight /2) * 2;
  }

  &.level4 {
    top: 71px + (@timesheetRowHeight /2) * 3;
  }

  &.level5 {
    top: 71px + (@timesheetRowHeight /2) * 4;
  }

  &::before {
    content: '';
    position: absolute;
    top: -13px;
    left: 211px;
    border: 6px solid transparent;
    border-bottom-color: darken(@silverTwo, 5%);
  }

  &::after {
    content: '';
    position: absolute;
    top: -12px;
    left: 211px;
    border: 6px solid transparent;
    border-bottom-color: @white;
  }

  .form-control[name="hours"] {
    display: inline-block;
    width: 70px;
  }

  label[for="hours"] {
    display: inline;
    margin-left: 10px;
    color: @gunmetal;
  }

  input[name="submitHours"] {
    &[disabled] {
      background-color: @darkSkyBlue50;
    }
  }

  .fa-times {
    float: right;
    font-size: 16px;
    color: @coolGrey;
    cursor: pointer;

    &:hover {
      color: @gunmetal;
    }
  }

  .comments-autosave {
    height: 12px;
    line-height: 12px;
    margin-bottom: 5px;
    .font8MediumSteel();
    animation: fadeinout_09 5s forwards;
  }

  .random-info {
    height: 12px;
    line-height: 12px;
    .font10RegularSteel();
    margin-bottom: 5px;
  }

  .comments-autosave-placeholder {
    height: 12px;
    margin-bottom: 5px;
  }
}
