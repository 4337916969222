@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* TimesheetCodeTotal styling */

.timesheet-code-total-container {
  width: @timesheetCodeTotalWidth;
  background-color: @paleGrey;
  display: flex;

  .fixed-circle-block {
    height: @timesheetRowHeight;
    width: 36px;
  }

  .circle-container {
    .noSelect();
    height: @timesheetRowHeight;
    width: 36px;

    .hover {
      &:hover {
        background-color: @paleGreyThree;
      }
    }

    .hover-completed {
      background-color: @paleGreyThree;

      &:hover {
        color: @darkGrey;
      }
    }

    .hover-can-incomplete {
      background-color: @paleGreyThree;
    }

    .last-circle {
      margin-right: 10px;
    }

    .circle {
      min-width: 36px;
      min-height: 36px;
      border-radius: percentage(1);
      margin-top: 10px;
      margin-bottom: 10px;

      &.hover-completed {
        .complete-timesheet {
          cursor: default;

          &:hover {
            cursor: default;
          }
        }
      }

      .complete-timesheet {
        line-height: 40px;
        text-align: center;
        font-size: 20px;
        color: @steel;

        &.complete-timesheet-hover {
          cursor: pointer;
          &:hover {
            color: @darkSkyBlue;
          }
        }
      }

      .trashbin {
        line-height: 40px;
        height: 36px;
        width: 36px;
        background: ~"url('~/../../images/trash.png')" no-repeat center;
        cursor: pointer;

        &:hover {
          background-image: ~"url('~/../../images/trash_blue.png')";
        }
      }

      .digital-signing {
        line-height: 40px;
        height: 36px;
        width: 36px;
        cursor: pointer;
        color: @steel;
        text-align:  center;

        &:hover {
          color: @darkSkyBlue;
        }
      }

      .export-tscode {
        line-height: 40px;
        height: 36px;
        width: 36px;
        background: ~"url('~/../../images/export-tscode.png')" no-repeat center;
        cursor: pointer;

        &:hover {
          background-image: ~"url('~/../../images/export-tscode-blue.png')";
        }
      }

      &.manage-clienttimesheet {
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        padding-left: 2px;
        padding-top: 1px;

        .manage-clienttimesheet-icon {
          color: @steel;
        }

        &:hover .manage-clienttimesheet-icon {
          color: @darkSkyBlue;
        }

        .manage-clienttimesheet-count{
          position: absolute;
          top:18px;
          left:23px;
          border-radius: 50%;
          font-size: 10px;
          line-height: 15px;
          width:15px;
        }
      }
    }
  }

  .tooltip(@textContent) {
    .font14RegularWhite();
    display: block;
    position: relative;
    opacity: 0.9;

    &:hover {
      text-decoration: none;

      &:before {
        content: '';
        display: block;
        border: 4px solid transparent;
        border-left: 4px solid @charcoalGrey;

        left: -4px;
        top: 16px;
        position: absolute;
        z-index: 99;
      }

      &:after {
        content: @textContent;
        display: block;
        background: @charcoalGrey;
        white-space: nowrap;
        border-radius: 4px;
        height: 28px;
        line-height: 28px;
        padding: 0 10px;

        right: 40px;
        top: 6px;
        position: absolute;
        z-index: 98;
      }
    }
  }

  .tooltip-export-weekly {
    .tooltip('Export weekly');
  }

  .tooltip-export-monthly {
    .tooltip('Export monthly');
  }

  .tooltip-remove-assignment {
    .tooltip('Remove this assignment');
  }

  .tooltip-complete-assignment {
    .tooltip('Complete this assignment');
  }

  .tooltip-uncomplete-assignment {
    .tooltip('Uncomplete this assignment');
  }

  .tooltip-manage-clienttimesheet {
    .tooltip('Manage timesheets from client');
  }

  .tooltip-digital-signing {
    .tooltip('Digital signing');
  }

  .timesheet-code-total {
    // flex: 1;
    height: @timesheetRowHeight;
    padding-left: 5px;
    padding-right: 2px;
    .font16MediumDarkGray();
    line-height: @timesheetRowHeight;
    width: 57px;

    .timesheet-code-total-text {
      margin-left: 15px;
      font-family: Rubik, sans-serif;
      font-weight: 500;
      font-size: 10px;
      color: @gunmetal;
    }
  }
}

.timesheet-code-total-container--width {
  .timesheet-code-total {
    flex: 1;
  }
}

.align-center {
  align-items: center;
}

@import "ExportMonthlyTimesheetModal";
@import "ExportWeeklyTimesheetModal";
