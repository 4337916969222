@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/KeyFrames";

/* TimesheetCode styling */

.timesheetcode-collapse {
  margin: 0 20px 0 10px;
  color: @steel;
}

.timesheetcode-inactive {
  text-decoration: line-through;
}

.readonly {
  :hover {
    cursor: default;
  }
}

.timesheetcode-container {
  .noSelect();
  width: @timesheetCodeWidth;
  background-color: @paleGrey;
  border-top: 1px solid @silver;
  height: @timesheetRowHeight;

  &:hover {
    cursor: pointer;
  }

  &.highlighted {
    border-left: solid 5px @darkSkyBlue;
  }

  &.timesheetcode-container-expanded {
    background-color: white;
  }

  .timesheetcode {
    height: @timesheetRowHeight;
    display: flex;
    align-items: center;

    .timesheetcode-inactive {
      text-decoration: line-through;
    }

    &.active-row-level {
      background-color: @paleGrey;
    }

    .timesheet-code-name-container {
      flex: 1;
      padding-left: 30px;

      .timesheet-code-name {
        .font14RegularGunmetal();
        width: 216px;
        word-wrap: break-word;


        &.active-row-level, &.highlighted {
          .font14MediumDarkSkyBlue();
        }

        &.timesheet-code-name-small {
          font-size: 12px;

          &.large-description {
            padding-top: 8px;
          }
        }
      }

      .timesheet-code-description {
        .font12RegularSteel();
        width: 216px;
        word-wrap: break-word;

        &.timesheet-code-description-small {
          .font10RegularSteel();
        }
      }
    }
  }

  .copy-timesheetcode-to-clipboard {
    .noSelect();
    opacity: 0;
    position: relative;
    top: ~"calc((@{timesheetRowHeight}/2 * -1) - 8px)";
    left: 10px;
    cursor: pointer;

    &.expanded {
      position: relative;
      top: ~"calc((@{timesheetRowExpandedHeight} * -1) + (@{timesheetRowHeight}/2) + 12px)";
    }

    &:hover {
      opacity: 1;
      animation: fadein_1 2.5s;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS */
        animation: fadein_1 0.4s
      }
    }
  }

  .copied {
    .noSelect();
    position: relative;
    top: ~"calc(@{timesheetRowHeight}/2 * -1)";
    left: 10px;
    width: 160px;
    background: @charcoalGrey;
    .font16LightWhite();
    z-index: 98;
    border-radius: 4px;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    animation: fadeinout_09 3s forwards;
  }
}

.timesheet-code-hours-container-collapsed {
  display: none;
}

.timesheet-code-hours {
  .noSelect();
  .font12RegularSteel();
  height: @timesheetRowHeight / 2;
  padding-left: 50px;
  line-height: @timesheetRowHeight / 2;
  background-color: white;

  &.active-row-level {
    background-color: @paleGrey;
  }

  &:hover {
    cursor: pointer;
  }
}
