
/* Digital Signing Styling*/

.digital-signing {
    .digital-signing-checkbox {
        display: flex;
        width: fit-content;

        label {
            margin-left: 10px;
        }

        :hover {
            cursor: pointer;
        }
    }
}

@import "DigitalSigningModal";