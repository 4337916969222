@import "../less/Fonts";

/* RefreshToast styling */

.refresh-browser-message {
  display: block;
}

.refresh-button {
  margin: 15px 20px 4px 0;
}