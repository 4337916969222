@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* TimesheetNavigation styling */

.month-week-container {
  display: flex;
  width: @timesheetCodeTotalWidth;
  height: @headerHeight;

  .month-week-month-block {
    width: @timesheetCodeTotalWidth / 2;

    .month-week-month-selector {
      float: right;
      margin-top: (@headerHeight - 30px) / 2;
      width: 70px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }
  }

  .month-week-week-block {
    width: @timesheetCodeTotalWidth / 2;

    .month-week-week-selector {
      margin-top: (@headerHeight - 30px) / 2;
      width: 70px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }

  .not-selected {
    border: solid 1px @darkSkyBlue;
    .font12MediumLightBlue();

    &:hover {
      cursor: pointer;
      background-color: lighten(@darkSkyBlue, 40%);
    }
  }

  .selected {
    background-color: @darkSkyBlue;
    .font12MediumWhite();
  }
}
