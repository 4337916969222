@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* TimesheetsLandscapeReport styling */

.timesheets-landscape-report {
  .noSelect();
  margin: 25px 0 0 25px;
  width: 100%;
  display: flex;

  .timesheets-information {
    flex: 0 0 20%;
    display: flex;
    flex-direction: column;

    .info-container {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      .font14RegularSteel();

      .Select.is-focused:not(is-open)>.Select-control {
        border-color: @darkSkyBlue;
      }

      .Select__control {
        width: 150px;
        .Select-multi-value-wrapper {
          .Select-value {
            .Select-value-label {
              .font14RegularSteel();
            }
            border: none;
          }
          .Select-input {
            input {
              border: none;
            }
            div {
              .font14RegularSteel();
            }
          }
        }
      }

      .month-picker {
        width: min-content;
        .box {
          width: max-content;
          .noSelect();
          cursor: pointer;
          label {
            cursor: pointer;
          }
          i {
            cursor: pointer;
            color: @steel;
            margin-left: 4px;
          }
        }
      }

      label {
        .noSelect();
        flex: 1;
        .font14RegularSteel();
      }

      input {
        //.noSelect();
        display: block;
        resize: none;
        padding: 8px;
        user-select: none;
        background-color: @white;
        border: solid 1px @silverTwo;
        text-align: center;
        .font14RegularGray();
        transition: border-color ease-in-out 0.15s;
        &:focus {
          border-color: #5cb3fd;
          outline: none;
        }
        &::placeholder {
          color: #636c72;
        }
        &:disabled {
          background-color: @disabledGrey;
        }
      }
    }

    .timesheets-buttons {
      .noSelect();
      display: flex;
      flex-direction: column;

      .export-button {
        display: block;
        padding: 7px 30px;
        margin-top: 10px;
        .font14RegularWhite();
      }
    }

    .report-export-spinner();
  }

  .landscape-selection-box-title {
    display: none;
  }

  .landscape-selection {
    flex: 1;
    display: flex;
    overflow: hidden;

    .landscape-selection-box {
      flex: 0 0 30%;
      margin: 0 auto;

      .extra-options-box {
        height: 75px;
        margin-bottom: 10px;
        width: 100%;

        .button {
          .noSelect();
          display: block;
          padding: 7px 30px;
          background-color: @darkSkyBlue;
          color: @white;
          cursor: pointer;
          text-align: center;
          text-transform: uppercase;
          .font14RegularWhite();

          &:hover {
            background-color: darken(@darkSkyBlue, 10%);
          }
        }
      }
    }

    .items-box {
      background-color: @backGroundGrey;
      border: 1px solid darken(@backGroundGrey, 0.2);

      .landscape-selection-box-title {
        display: block;
        width: 100%;
        text-align: center;
        background-color: @backGroundGrey;
        border: 1px solid darken(@backGroundGrey, 0.2);
        border-bottom: 0;
        .font19MediumSteel();
        padding: 8px;
      }

      .suggestedList {
        width: 100%;
        height: 88%;
        text-align: center;

        .suggestion {
          .noSelect();
          padding: 8px;
          .font14RegularSteel();

          &:hover {
            cursor: pointer;
            color: @white;
            background-color: @darkSkyBlue;
            opacity: 0.65;
          }
        }

        .selected {
          color: @white;
          background-color: @darkSkyBlue;
        }
      }
    }

    .landscape-selection-empty-box {
      flex: 0 0 30%;
      margin: 0 auto;
    }
  }
}

@import "AutoSuggestList";
