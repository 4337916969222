
/* ReleaseNotes styling */

.releasenotes {
    font-family: Rubik, sans-serif;
    width: fit-content;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: auto;
    margin-bottom: auto;

    h1, h2, h3, h6 {
        font-weight: 400;
    }
}