@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* AddTimesheetCodeModal styling */

.closable-modal {
  .add-selected-button {
    margin-top: 20px;
  }

  .searchResultMessage {
    float: left;
    margin: -16px 0 5px 0;
    left: -5px;
    .font12LightSteel();
  }

  .addTimesheetCode-selectAll {
    line-height: 20px;
    margin-bottom: 28px;
    .noSelect();
  }

  .addTimesheetCode-selectAll-checkbox {
    float: left;
  }

  .addTimesheetCode-selectAll-checkbox-text {
    float: left;
    .font12RegularDarkSkyBlue();
  }

  .timesheet-code-tab-container {
    display: flex;

    .timesheet-code-tab {
      .noSelect();
      .font12MediumGunMetal();
      flex: 1;
      line-height: 1.75;

      &:hover {
        cursor: pointer;
      }

      &.active {
        .font12MediumBlack();
      }

      .line {
        margin-top: 10px;
        border-bottom: solid 1px @paleGreyThree;

        &.black {
          margin-top: 10px;
          border-bottom: solid 1px @gunmetal;
        }
      }
    }
  }

  .timesheet-code-tab-content-timesheet-codes {
    height: 300px;
    width: 100%;
    display: block;
    overflow: hidden;
    margin-top: 15px;

    .no-most-recent-tscodes {
      .noSelect();
      .font19LightSteel();
      float: left;
      padding-top: 5px;
    }

    .tscode-select {
      display: inline-flex;
      width: 98%;
      box-sizing: content-box;
      padding: 5px 0 5px 0;

      .tscode-select-checkbox {
        float: left;
        padding: 5px 10px 5px 10px;
      }

      .tscode-select-container {
        .noSelect();
        float: left;

        .tscode-select-name {
          .font14RegularGunmetal();
        }

        .tscode-select-description {
          .font12RegularGunmetal();
          color: lighten(@gunmetal, 20%);
        }
      }
    }

    .scrollBar {
      max-height: 80%;
    }
  }

  .add-selected-button-container {
    .clearFix();
  }
}
