@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* ChangeUserModal styling */

.profileDropDownFlexer {
    .change-user-input {
        width: 100%;
        width: stretch;
        height: 30px;
        margin-top: 20px;
        margin-bottom: 30px;

        &:focus {
            outline-color: @paleGreyThree;
            outline-width: 0.25px;
        }
    }

    .change-user-list {
        .myself {
            margin-bottom: 20px;
        }
        .change-user-scrollbar {
            height: 400px;
        }

        .change-user-list-item {
            display: flex;
            align-items: center;
            background-color: @white;
            padding: 10px 0;

            .change-user-avatar {
                margin: 0 20px;
                min-width: 27px;
                min-height: 27px;
                background-color: @darkSkyBlue50;
                border-radius: percentage(1);
                .change-user-avatarFilling {
                    .font12MediumWhite();
                    line-height: 27px;
                    text-align: center;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .change-user-username {
                .font16LightDarkSkyBlue();
                &:hover {
                    cursor: pointer;
                }
            }
            &:hover {
                background-color: @paleGreyThree;
                cursor: pointer;
            }
            &.changeUserSelected  {
                background-color: @paleGreyThree;
            }
        }

    }
}
