@import "../../../../../less/Variables";

/* Approver Styling*/

.approver {
    display: flex;;
    margin-bottom: 12px;

    .fa-times {
        font-size: 16pt;
        cursor: pointer;
        color: @silverTwo;
        margin-left: 10px;
        line-height: 35px;;

        &:hover {
            transition: color ease-in-out 0.15s;
            color: @warmPink;
        }
    }

    .input-name {
        width: 30%;
    }

    .input-emailaddress {
        width: 57%;
        margin-right: 3%;
    }

    input {
        width: 100%;
        height: 35px;
        padding: 8px 16px;
        background-color: @white;
        border: solid 1px @silverTwo;
        .font14RegularGray();
        transition: border-color ease-in-out 0.15s;

        &:focus {
            border-color: #5cb3fd;
            outline: none;
        }

        &::placeholder {
            color: #BBB;
        }
    }

    .tooltip(@textContent) {
        .font14RegularWhite();
        display: block;
        position: relative;
        opacity: 0.9;

        &:hover {
          text-decoration: none;

          &:after {
            content: @textContent;
            display: block;
            background: @warmPink;
            white-space: nowrap;
            height: 28px;
            line-height: 28px;
            padding: 0 10px;
            position: block;
            z-index: 98;
          }
        }
    }

    .input-name[data-required],
    .input-emailaddress[data-required] {
        .tooltip(attr(data-required));
    }

    input[data-required] {
        border-color: @warmPink;
    }
}