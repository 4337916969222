@import '../../../../less/Fonts';

.progress-step {
  &.allow-hover {
    cursor: pointer;
  }

  .progress-marker {
    &::before {
      border-radius: 10%;
    }
  }

  &.no-hover {
    &:hover {
      .progress-marker::before {
        background-color: #b6b6b6;
      }
    }
  }

  &.is-complete.no-hover {
    &:hover {
      .progress-marker::before {
        background-color: #1976D2;
      }
    }
  }

  &.is-active.no-hover {
    &:hover {
      .progress-marker::before {
        background-color: #2196F3;
      }
    }
  }

  .progress-text {
    .noSelect();
    .font14RegularSteel();
    font-weight: 100;
    text-transform: uppercase;

    .progress-title {
      &.startover {
        .font14MediumBlack();
        cursor: pointer;
        padding-left: 8px;

        .fa-redo {
          font-size: 18px;
        }
      }
    }
  }
}
