@import "../../../less/Variables";
@import "../../../less/Fonts";

@import '../../../../node_modules/react-toastify/dist/ReactToastify.css';

/* Content styling */

.content {
  height: @timesheetHeight;
  .default-content {
    height: 100%;
  }
}

.inMaintenance {
  text-transform: uppercase;
  .font14MediumWarmPink();
  .noSelect();
}

.toastify {
  .noSelect();
  line-height: 18px;

  &.info {
    background-color: #0A80FC;
    border: 1px dotted darken(#0A80FC, 10%);
  }

  &.error {
    background-color: indianRed;
    border: 1px dotted darken(indianRed, 10%);
  }

  .toastify-body {
    .font14RegularWhite();
    text-align: left;
    margin: 0 5px;

    &.info {
      background-color: #0A80FC;
    }

    &.error {
      background-color: indianRed;
    }
  }
}

.back-close-icon {
  color: @coolGrey;
  position: relative;
  left: 430px;

  .fa-times {
    font-size: 16px;
    cursor: pointer;

    &:hover {
      color: @gunmetal;
    }
  }
}

@import "timesheet/Timesheet";
@import "reporting/Reporting";
@import "releasenotes/ReleaseNotes";
@import "import/ImportActuals";
@import "../../../common/RefreshToast";
@import "../../../common/IncompleteMonthsToast";
@import "location/SiteLocation";
@import "invoice/InvoiceViewer";
@import "invoice/SentInvoice";
@import "login/LoginModal";
