@import "../../../../less/Fonts";

/* MaintenanceModeDisclaimer styling */

.maintenancemode-disclaimer {
  position: absolute;
  bottom: 2px;
  left: ~"calc(100%/2/2)";
  text-transform: uppercase;
  .defaultFontRegular(16px, red);
}
