@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* SentInvoice styling */

.sent-invoice {
  .noSelect();
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 15px;

  .fas {
    padding-right: 4px;
    line-height: 44px;
    width: 8px;
  }

  .sent-invoice__list-item {
    .font14RegularBlack();
    display: flex;
    line-height: 46px;
    width: 100%;

    .sent-invoice__list-header__invoicedate,
    .sent-invoice__list-header__supplier,
    .sent-invoice__list-header__invoicenumber,
    .sent-invoice__list-header__customer,
    .sent-invoice__list-header__amount,
    .sent-invoice__list-header__paid {
      overflow: hidden;

      .fas {
        padding-right: 10px;
      }
    }

    .sent-invoice__list-header__invoicedate__title,
    .sent-invoice__list-header__supplier__title,
    .sent-invoice__list-header__invoicenumber__title,
    .sent-invoice__list-header__customer__title,
    .sent-invoice__list-header__amount__title,
    .sent-invoice__list-header__paid__title {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .sent-invoice__list-header__invoicedate {
      display: flex;
      padding: 0 5px;
      width: 135px;
      height: 46px;
      cursor: pointer;
      text-align: right;
    }

    .sent-invoice__list-filter__invoicedate {
      display: flex;
      width: 135px;
      height: 35px;
      margin-top: -5px;
      cursor: pointer;
    }

    .sent-invoice__list-item__invoicedate {
      padding: 0 5px;
      width: 130px;
      text-align: right;
    }

    .sent-invoice__list-header__supplier {
      display: flex;
      padding: 0 5px;
      width: 380px;
      cursor: pointer;
    }

    .sent-invoice__list-item__supplier {
      padding-left: 20px;
      padding-right: 5px;
      width: 380px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .sent-invoice__list-header__invoicenumber {
      display: flex;
      padding: 0 35px;
      padding-left: 20px;
      width: 230px;
      cursor: pointer;
    }

    .sent-invoice__list-item__invoicenumber {
      padding: 0 35px;
      width: 230px;
      user-select: all;

      &::selection {
        background: darken(@darkSkyBlue,10);
      }
    }

    .sent-invoice__list-header__customer {
      display: flex;
      padding: 0 5px;
      width: 380px;
      cursor: pointer;
    }

    .sent-invoice__list-item__customer {
      padding-left: 20px;
      padding-right: 5px;
      width: 380px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .sent-invoice__list-header__amount {
      display: flex;
      padding-left: 5px;
      width: 88px;
      text-align: right;
      cursor: pointer;
    }

    .sent-invoice__list-item__amount {
      width: 88px;
      text-align: right;
    }

    .sent-invoice__list-header__paid {
      display: flex;
      padding: 0 20px;
      width: 110px;
      text-align: center;
      cursor: pointer;
    }

    .sent-invoice__list-item__paid {
      padding: 0 5px;
      width: 110px;
      padding-left: 55px;
    }

    .sent-invoice__list-item__download {
      padding: 0 25px;

      .fas.fa-cloud-download-alt {
        vertical-align: middle;
      }

      &:hover {
        cursor: pointer;
        color: ghostwhite;
      }
    }

    .sent-invoice__list-item__owner {
      font-weight: 500;
      color: darkslategray;
    }

    &:nth-child(even) {
      background-color: @paleGrey;
    }

    &:hover {
      background-color: @darkSkyBlue50;
      cursor: pointer;
    }

    &.sent-invoice__list-item--fixed {
      background-color: @darkSkyBlue;
      margin-top: 15px;
      margin-bottom: 10px;
      font-weight: 500;
      color: white;
      text-transform: uppercase;
      font-size: 14px;

      &:hover {
        cursor: default;
        color: white;
        background-color: @darkSkyBlue;
      }
    }
  }

  .sent-invoice__list-filter {
    margin-top: -10px;
    background-color: lighten(@backGroundGrey, 10%);
    .font14RegularBlack();
    display: flex;
    line-height: 40px;
    width: 100%;

    .sent-invoice__list-filter__invoicedate__input,
    .sent-invoice__list-filter__supplier__input,
    .sent-invoice__list-filter__invoicenumber__input,
    .sent-invoice__list-filter__customer__input,
    .sent-invoice__list-filter__amount__input,
    .sent-invoice__list-filter__paid__input {
      visibility: visible;
      width: 100%;
      margin-right: 10px;

      .form-group {
        margin-bottom: 5px;
        margin-top: 3px;
      }
    }

    input[type="search"] {
      .font16RegularGray();
      height: 20px;
      margin-top: 5px;

      background: transparent;
      border: none;
      border-bottom: 1px solid @grey;


      &::-webkit-search-cancel-button {
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 1em;
        width: 1em;
        background: url(/images/times.svg) no-repeat 50% 50%;
        background-size: contain;
      }
    }

    .sent-invoice__list-filter__invoicedate {
      display: flex;
      width: 135px;
    }

    .sent-invoice__list-filter__supplier {
      display: flex;
      padding: 0 5px;
      width: 380px;

      .sent-invoice__list-filter__supplier__input {
        margin-left: 10px;
      }
    }

    .sent-invoice__list-filter__invoicenumber {
      display: flex;
      padding: 0 35px;
      padding-left: 20px;
      width: 230px;

      .sent-invoice__list-filter__invoicenumber__input {
        margin-left: 10px;
      }
    }

    .sent-invoice__list-filter__customer {
      display: flex;
      padding: 0 5px;
      width: 380px;

      .sent-invoice__list-filter__customer__input {
        margin-left: 10px;
      }
    }

    .sent-invoice__list-filter__amount {
      display: flex;
      padding-left: 5px;
      width: 100px;
      text-align: right;

      .sent-invoice__list-filter__amount__input {
        margin-left: 10px;
      }
    }

    .sent-invoice__list-filter__paid {
      display: flex;
      padding: 0 20px;
      width: 110px;
      text-align: center;
      line-height: 44px;

      .sent-invoice__list-filter__paid__input {
        .font18MediumGray();

        .fas.fa-check,
        .fas.fa-times {
          margin-left: -18px;
          margin-top: -10px;
        }
      }
    }

    .sent-invoice__list-filter__owner {
      font-weight: 500;
      color: darkslategray;
    }
  }

  .sent-invoice__scrollbarcontainer {
    height: ~"calc(100% - 146px)";
    overflow: auto;
    .styled-scrollbar();
  }
}
