@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";


/* ExportMonthlyTimesheetModal styling */

.closable-modal {
  .noSelect();

  .hide-comments-checkbox {
    margin-left: 10px;
  }

  .export-selected-download {
    height: 30px;
    line-height: 30px;
    display: flex;
    background-color: @darkSkyBlue;
    margin-top: 20px;
    margin-left: 0px;
    width: 150px;
    border-radius: 50px;
    float: right;
    padding: 0 30px;
    .font12RegularWhite();

    .fa-sort-up {
      margin-left: 10px;
      line-height: 37px;
      font-size: 18px;
    }

    &:hover{
      cursor: pointer;
      background-color: darken(@darkSkyBlue, 10%);
    }
  }

  .export-selected-download-open {
    background-color: lighten(@darkSkyBlue, 20%);

    &:hover{
      background-color: lighten(@darkSkyBlue, 10%);
    }
  }

  .exportMonthly-selectAll {
    line-height: 20px;
    margin-bottom: 28px;
  }

  .exportMonthly-selectAll-checkbox {
    float: left;
  }

  .exportMonthly-selectAll-checkbox-text {
    float: left;
    .font12RegularDarkSkyBlue();
  }

  .export-monthly-container {
    .font14RegularSteel();
  }

  .timesheetcodes-container {
    height: 150px;
    display: block;
    overflow: hidden;

    &.readonly {
      input[type="checkbox"] {
        &:checked {
          background-color: @checkboxReadonly;
        }
      }
    }

    .tscode-select {
      display: inline-flex;
      width: 98%;
      box-sizing: content-box;
      padding: 5px 0 5px 0;

      .tscode-select-checkbox {
        float: left;
        padding-left: 10px;
        margin-top: -2px;
      }

      .tscode-select-name {
        .font14RegularGunmetal();
      }
    }
  }

  .react-autosuggest__input {
    display: block;
    width: 100%;
    height: 35px;
    padding: 8px 16px;
    background-color: @white;
    border: solid 1px @silverTwo;
    .font14RegularGray();
    transition: border-color ease-in-out 0.15s;

    &:focus {
      border-color: #5cb3fd;
      outline: none;
    }

    &::placeholder {
      color: #BBB;
    }
  }

  .react-autosuggest__suggestions-container {
    width: 440px;
  }

  .scrollBarContainer {
    height: 120px;
  }

  .export-selected-button-container {
    position: relative;
    .clearFix();

    .export-selected-buttons {
      .export-selected-button {
        margin-top: 10px;
        width: 150px;
        margin-left: 30px;
      }

      width: 200px;
      height: 100px;
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: white;
      right: 148px;
      top: -100px;
      border-radius: 3px;
      animation: slide-up 0.5s ease;

      @keyframes slide-up {
        0% {
            opacity: 0;
            transform: translateY(5px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
      }
    }
  }
}

.export-monthly-timesheet-modal__required_message {
  .font12RegularSteel();
  margin-top: -15px;
}

label.export-monthly-timesheet-modal__required {
  &:after{
    content: '*';
    margin-left: 5px;
  }
}

label[data-required] {
  color: @warmPink;

  &:after {
    content: '*';
    color: @warmPink;
    margin-left: 5px;
    animation: slide-up 0.5s ease;

    @keyframes slide-up {
      0% {
          opacity: 0;
          transform: translateY(5px);
      }
      100% {
          opacity: 1;
          transform: translateY(0px);
      }
    }
  }
}
