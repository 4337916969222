@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* Settings styling */

.modal-dialog {
    &.settings-modal {
        width: 550px;
    }
}

.settings-item {
    .noSelect();
    display: flex;
    align-items: center;
    padding: 15px 0;
    .item-title {
        width: 35%;
        .font16LightDarkSkyBlue();
    }
    .toggle {
        width: 65%;
        .toggle-value1 {
            width: 50%;
        }
        .toggle-value2 {
            width: 50%;
        }
    }
}
