@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* Reporting styling */

.reporting {
    width: 100%;
    height: @timesheetHeight;
    display: flex;
}

@import "ReportingContainer";
@import "ActualsReport";
@import "TimesheetsReport";
@import "TimesheetsWeeklyReport";
@import "TimesheetsLandscapeReport";
@import "TimeLeftOnOrderReport";
@import "ProjectTimesheetReport";
