/* TimesheetFooter styling */
@import "../../../../less/Variables";

.timesheet-footer {
  flex: 1 0 155px;
  display: flex;
  position: relative;

  .timesheet-footer-container {
      width: ~"calc(100% - @{timesheetCodeWidth})";
  }

  .timesheet-footer-container--separator {
    float: right;
    width: 23px;
    height: 200px;
  }

  .timesheet-code-column-placeholder {
    border-top: solid 1px @silver;
    width: 290px;
  }

  .input-submit-darkskyblue {
    .font12RegularSteel();
    width: 200px;
    height: 45px;
    border: solid 1px @coolGrey;
    border-radius: 50px;
    background-color: @white;
    float: right;
    transition: all ease-in-out 0.2s;
    cursor: pointer;

    &:hover, &:active {
      background-color: darken(@darkSkyBlue, 10%);
      color: @white;
    }

    &:focus {
      box-shadow: 0 0 0 2px fade(@coolGrey, 50%);
      outline: none;
    }

    &:disabled {
      &:hover {
        background-color: @darkSkyBlue50;
        transition: none;
      }
    }
  }
}

@import "TimesheetCodeColumnPlaceHolder";
@import "TimesheetComplete";
@import "TimesheetCompleteModal";
@import "Version";
@import "ExportMenu";
@import "Legend";
@import "ManageClientTimesheet";
@import "ManageClientTimesheetModal";
@import "Disclaimer";
@import "MaintenanceModeDisclaimer";
@import "DigitalSigning/DigitalSigning";