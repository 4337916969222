@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* ImportActuals styling */

.import-actuals {

  .import-actuals-content {
    margin: 0 auto;
    .font12RegularBlack();
    padding: 25px 5%;

    .dropzone-import-actuals-disabled {
      display: flex;

      .fa-stack {
        color: @darkGrey;
        font-size: 32px;
        height: 32px;

        .fa-stack-1x {
          top: -48%;
        }

        .fa-stack-2x {
          font-size: 12px;
          top: 38%;
        }
      }
    }

    input[type=submit] {
      margin-left: 0px;
      float: inherit;

      &:disabled {
        background-color: @darkSkyBlue50;
        transition: none;

        &:hover {
          background-color: @darkSkyBlue50;
        }
      }
    }

    .dropzone {
      padding: 50px 10px 10px 10px;
      display: inline;

      .dropzone-content {
        height: 300px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: lighten(@black10,25%);
        border-radius: 2px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        border: thin dashed darken(@darkSkyBlue, 5%);
        text-align: center;
        cursor: pointer;
        margin: 0 auto;
        padding: 5px;

        &.loading {
          cursor: default;

          &:hover {
            opacity: 1;
          }
        }

        .dropzone-upload-logo {
          font-size: 40px;
          color: @darkGrey;

          .fa-stack-2x {
            font-size: 16px;
            top: 40%;
          }
        }

        &:hover {
          opacity: 0.85;
        }
      }
    }

    .import-actuals-error-message {
      .noSelect();
      .font14RegularWarmPink();
      height: 35px;
      line-height: 25px;
      padding: 5px 5px;
      margin: 10px 0;
    }

    .import-actuals-success-message {
      .noSelect();
      .font24RegularSteel();
      text-align: center;
    }

    .import-actuals-success-startover {
      .noSelect();
      text-align: center;

      .import-actuals-success-startover-text {
        cursor: pointer;
        .font24MediumBlack();
        padding-top: 160px;

        &:hover {
          color: lighten(black, 40%);
        }
      }

      .import-actuals-success-startover-icon {
        cursor: pointer;
        .font128MediumBlack();

        &:hover {
          color: lighten(black, 40%);
        }
      }
    }

    .import-actuals-row-header {
      .noSelect();
      .font12RegularBlack();
      display: flex;
      background: @darkSkyBlue50;
      line-height: 25px;
      text-transform: uppercase;
    }

    .import-actuals-row-username {
      padding: 0 10px;
      width: 5.78%;
      text-align: left;
    }

    .import-actuals-row-tscode {
      padding: 0 10px;
      width: 16.78%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .import-actuals-row-workday {
      padding: 0 10px;
      width: 4.92%;
      text-align: right;
    }

    .import-actuals-row-workmonth {
      padding: 0 10px;
      width: 5.79%;
      text-align: right;
    }

    .import-actuals-row-workyear {
      padding: 0 10px;
      width: 5.79%;
      text-align: right;
    }

    .import-actuals-row-actualhours {
      padding: 0 10px;
      width: 6.37%;
      text-align: right;
    }

    .import-actuals-row-extrahours {
      padding: 0 10px;
      width: 6.37%;
      text-align: right;
    }

    .import-actuals-row-standbyhours {
      padding: 0 10px;
      width: 6.94%;
      text-align: right;
    }

    .import-actuals-row-interventionhours {
      padding: 0 10px;
      width: 8.68%;
      text-align: right;
    }

    .import-actuals-row-comments {
      padding: 0 10px;
      width: 18.52%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      white-space: nowrap;
    }

    .import-actuals-row-processstatus {
      padding: 0 10px;
      width: 13.89%;
      text-align: left;
    }
  }

  .import-actuals-footer {
    position: sticky;
    width: 100%;
    height: 25px;
  }
}

@import "ImportActualsRow";
@import "ImportActualsTemplateLegend";
@import "ProgressTracker";
