@import "../../../../less/Variables";

/* TimesheetBorders styling */

.timesheet-code-container, .timesheet-code-container-readonly, .day-hours,
.add-timesheet-code, .timesheet-footer-container, .timesheet-row-filler-left {
  border-top: @regularBorder;
  border-left: @regularBorder;
}

.timesheet-code-total-container {
  border-left: @regularBorder;

  .timesheet-code-total, .circle-container, .fixed-circle-block {
    border-top: @regularBorder;
  }
}

.timesheet-row-filler-right, .day-hours-weekly {
    border-top: @regularBorder;
}

.day-hours-extra, .actions-extra {
  border-left: @regularBorder;
}

.timesheet-code-highlighted-container {
  border-left: @bigBlueBorder;

  .timesheet-code-highlighted {
    border-top: @regularBorder;
  }
}
