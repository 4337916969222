@import "../../../less/Fonts";

/* DropDown Styling*/

.dropdown {
    float: right;

    .dropdown-submit {
        .font12MediumSteel();
        width: @footerButtonWidth;
        height: @footerButtonHeight;
        margin: 23px 0px 0 0px;

        &.open {
            border-radius: 0px 0px 5px 5px;
            border-top: 0px;
            color: white;
            background-color: @darkSkyBlue;
            box-shadow: none;
            transition: all 0.15s ease-in, border-top-left-radius 0.2s, border-top-right-radius 0.2s;
        }

        &:hover{
            color: white;
        }

        &:disabled {
            cursor: default;
            color: @steel;
            background-color: @coolGrey;
            transition: none;
        }

        &:disabled:hover {
            color: @steel;
            background-color: @coolGrey;
            transition: none;
        }
    }

    .dropdown-container {
        .font12MediumSteel();
        position: relative;
        top: 15px;
        width: 100%;
        line-height: 45px;
        text-align: center;
        background-color: white;
        padding: 0 5px;

        &.buttoncount2 {margin-top: -120px;}
        &.buttoncount3 {margin-top: -179px;}
        &.buttoncount4 {margin-top: -239px;}
        &.buttoncount5 {margin-top: -298px;}

        &.open {
            border: 1px solid @coolGrey;
            border-bottom: 0px;
            padding-bottom: 10px;
            border-radius: 5px 5px 0px 0px;
        }

        .dropdown-container-item {
            cursor: pointer;
            border: 1px solid @steel;
            border-radius: 50px;
            margin-top: 12px;

            &:hover {
              color:white;
              background-color: @darkSkyBlue;
            }
          }
    }
}