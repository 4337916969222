@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* Identity styling */

.identity {
  flex: 1;
  display: flex;

  .home {
    display: flex;
    align-items: center;
    padding-right: 80px;
    .noSelect();
    cursor: pointer;

    .logo {
      position: relative;
      height: 30px;
      left: 30px;
    }

    .title {
      position: relative;
      left: 50px;
      .font20BoldWhite();
      text-transform: uppercase;
    }
  }
}
