@import "../less/Variables";
@import "../less/Fonts";

/* Preset styling */

.preset {
  .noSelect();
  height: 44px;
  display: flex;

  .Select-value {
    display: flex;
    height: 30px;
    line-height: 30px;

    .Select-value-label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .Select-value__option {
      width: 230px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.default {
        color: darken(@paleGrey, 20%);
        width: 100%;
      }

      &:hover {
        background-color: @paleGrey;
      }

      &:hover {
        .Select-value__action {
          background-color: lighten(@pigPink, 4%);
        }
      }
    }

    .Select-value__action {
      cursor: pointer;
      width: 16px;
    }

    &:hover {
      .fas.fa-times {
        color: @pigPink;
        visibility: visible;
        padding-left: 4px;
        padding-right: 4px;

        &:hover {
          color: darken(@pigPink,15%);
        }
      }
    }

    .fas.fa-times {
      justify-content: flex-end;
      visibility: hidden;
    }
  }

  .preset__modal {
    .preset__modal_container {
      overflow: hidden;

      .preset__modal_container_showpreset--scrollheight {
        height: 94%;
      }

      .preset__modal_container_showpreset {
        height: 200px;
        margin-top: 10px;
        margin-bottom: 20px;
        padding-left: 10px;
        border: solid 1px @backGroundGrey;
        .styled-scrollbar();

        .preset__modal_container_showpreset__title {
          .font12MediumLightBlue();
          text-transform: uppercase;
        }

        .preset__modal_container_showpreset__item {
          .font10LightSteel();
          margin-left: 10px;
        }

        .preset__modal_container_showpreset_noselection {
          .font12LightDarkSkyBlue();
        }
      }

      button {
        .font12MediumWhite();
        cursor: pointer;
        float: right;
        text-transform: uppercase;
        padding: 0 30px;
        height: 30px;
        border: none;
        border-radius: 50px;
        background-color: @darkSkyBlue;
        text-align: center;
        transition: all ease-in-out 0.2s;

        &.inverse {
          color: @steel;
          border: solid 1px @coolGrey;
          background-color: white;
        }

        &:hover, &:active {
          background-color: darken(@darkSkyBlue, 10%);

          &.inverse {
            color: @white;
          }
        }

        &:focus {
          box-shadow: 0 0 0 2px fade(@darkSkyBlue, 50%);
          outline: none;

          &.inverse {
            box-shadow: 0 0 0 2px fade(@coolGrey, 50%);
          }
        }

        &[disabled=''] {
          cursor: default;
          color: @white;
          background-color: @darkSkyBlue50;
          transition: none;
        }
      }

      .clearFix();
    }
  }

  .preset__select {
    .font14RegularSteel();
    width: 250px;
  }

  .preset__submit {
    .font14RegularSteel();
    margin-left: 8px;
    height: 36px;
    float: right;
    vertical-align: middle;
    background-color: @darkSkyBlue;
    color: white;
    outline: none;
    border: none;

    &[disabled=''] {
      cursor: default;
      transition: none;
      color: darken(@disabledGrey, 25);
      background-color: @disabledGrey;

      &:hover {
        cursor: default;
        background-color: @disabledGrey;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: @windowsBlue;
    }
  }
}
