@import "../../../../../less/Fonts";

/* Digital Signing Wizard Overview Styling*/

.digital-signing-wizard-overview {
    min-height: 480px;
    .font16MediumSteel();

    .overview-item  {
        display: block;

        .overview-item-title {
            padding: 10px 0;
        }

        .overview-item-value {
            .font14RegularSteel();
            padding-bottom: 5px;
        }

        &.oneline {
            display: flex;

            .overview-item-title {
                margin-right: 10px;
            }

            input {
                margin: 12px 0;

                &:hover {
                    cursor: pointer;
                }
            }

            i {
                line-height: 36px;
            }
        }
    }

    .overview-container {
        display: flex;

        .overview-container-column {
            &.left {
                width: 55%;
                display: flex;
                flex-direction: column;

                .oneline {
                    flex: 1;
                }

                .infotext-container {
                    width: 85%;
                }
            }
            &.right {
                width: 45%;
            }
        }
    }
}