/* WeekSelection Styling*/

.week-selection {
    .weeks-container {
        height: 100%;
        display: block;
        overflow: hidden;
    
        .week-select {
          display: inline-flex;
          width: 98%;
          box-sizing: content-box;
          padding: 5px 0 5px 0;
    
          .week-select-checkbox {
            float: left;
            padding-left: 10px;
            margin-top: -2px;
    
            &.readonly {
              input[type="checkbox"] {
                &:checked {
                  background-color: @checkboxReadonly;
                }
              }
            }
          }
    
          .week-select-number {
            .week-select-weeknumber {
              width: 60px;
              .font14RegularGunmetal();
              float: left;
            }
    
            .week-period {
              width: 160px;
              float: left;
              margin-top: -1px;
              margin-left: 15px;
              .defaultFontRegular(12px, lighten(@gunmetal, 35%));
    
              .week-period-date {
                float: left;
                width: 65px;
              }
    
              .week-period-dash {
                float: left;
                width: 20px;
                text-align: center;
              }
            }
          }
        }
      }
}