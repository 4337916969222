
@import "../../../../../less/Variables";
@import "../../../../../less/Fonts";
@import "../../../../../less/Mixins";

/* DigitalSigningWizardManageApprovers Styling*/

.wizard-screen-manage-approvers {
    min-height: 480px;
    .styled-scrollbar();

    .add-new-approver {
        width: fit-content;
        padding: 0 0 10px 0;
        .font12MediumDarkSkyBlue();
        display: flex;
        line-height: 14px;
        text-transform: uppercase;

        .add-new-approver-text {
            padding-left: 5px;
        }

        &:hover {
            color: darken(@darkSkyBlue, 10%);
            cursor: pointer;
        }
    }

    .title {
        label {
            .font18RegularSteel();

            &[data-required] {
                font-size: 18px; //override the default error size
            }
        }
    }
    .approver-labels {
        margin-bottom: 5px;

        .label-name {
            width: 30%;
        }

        .label-emailaddress {
            width: 60%;
        }
    }

    .scroll-height {
        height: 275px;
    }

    .info-container {
        margin-bottom: 10px;
    }
}

@import "Approver";
@import "../../../common/InfoContainer";
