@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* ImportActualsRow styling */

.import-actuals-row {
    display: flex;
    line-height: 30px;
    .font12RegularBlack();

    &.in-error {
        color: @warmPink
    }

    &:nth-child(even) {
        background-color: @paleGrey;
    }
}
