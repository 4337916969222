@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* ManageClientTimesheetModal styling */

.manage-client-timesheet-modal {
  .noSelect();
  margin: 25px 0;
  width: 100%;

  .manage-client-timesheet-modal__picker {
    display: flex;
    line-height: 20px;

    .manage-client-timesheet-modal__picker__label {
      .font14RegularSteel();
      padding-right: 8px;
    }

    .manage-client-timesheet-modal__picker__monthpicker {
      .font14RegularGunmetal();
      vertical-align: bottom;
    }
  }

  .manage-client-timesheet-modal__documentlist {
    .font12RegularGunmetal();
    color: lighten(@gunmetal, 20%);
    height: 120px;
    width: 100%;
    margin-top: 15px;

    .manage-client-timesheet-modal__documentlist__header {
      margin-bottom: 5px;
    }

    .manage-client-timesheet-modal__documentlist__item {
      display: flex;
      line-height: 25px;

      .manage-client-timesheet-modal__documentlist__item__name {
        width: 380px;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        // flex-grow: 1;
        margin-left: 5px;
        margin-right: 10px;

        &:hover {
          cursor: pointer;
          font-weight: 500;
        }
      }

      .manage-client-timesheet-modal__documentlist__item__date {
        width: 135px;
        margin-right: 5px;
        text-align: left;
      }

      .trashbin {
        height: 25px;
        width: 40px;
        background: ~"url('~/../../images/trash.png')" no-repeat center;

        &:hover {
          cursor: pointer;
          background-image: ~"url('~/../../images/trash_blue.png')";
        }
      }
    }

    .manage-client-timesheet-modal__documentlist__nocontent {
      .font14RegularGunmetal();
      margin-top: 10px;
    }
  }

  .manage-client-timesheet-modal__tscodelist {
    .font14RegularGunmetal();
    color: lighten(@gunmetal, 20%);
    height: 220px;
    width: 100%;
    margin-top: 15px;

    .manage-client-timesheet-modal__tscodelist__header {
      margin-bottom: 5px;
    }

    .manage-client-timesheet-modal__tscodelist__item {
      display: flex;
      line-height: 20px;
      cursor: pointer;

      .manage-client-timesheet-modal__tscodelist__item__name {
        margin-top: 1px;
        .font14RegularGunmetal();
      }
    }

    .manage-client-timesheet-modal__tscodelist__nocontent {
      .font14RegularGunmetal();
      margin-top: 10px;
    }
  }

  .manage-client-timesheet-modal__file-upload {
    margin-top: 50px;

    .manage-client-timesheet-modal__file-upload__dropzone-content {
      .font12LightBlack();
      cursor: pointer;

      .manage-client-timesheet-modal__file-upload__dropzone-content__container {
        height: 110px;
        margin: auto;
        text-align: center;
        border: thin dashed darken(@darkSkyBlue, 5%);
        border-radius: 2px;
        box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2);

        &:hover {
          background-color: lighten(@darkSkyBlue, 40%);
        }

        .manage-client-timesheet-modal__file-upload__dropzone-content__container__upload-logo {
          margin-top: 10px;
          font-size: 60px;
          color: lighten(@gunmetal, 40%);
        }

        .manage-client-timesheet-modal__file-upload__dropzone-content__container__text {
          margin-top: 10px;
        }
      }
    }

    .manage-client-timesheet-modal__file-upload__complete {
      .font12RegularGunmetal();
      height: 110px;
      text-align: center;

      .fas.fa-check {
        color: @darkSkyBlue;
        opacity: 0.7;
        font-size: 80px;
        text-shadow: 1px 0 5px @darkGrey;
      }
    }
  }

  .manage-client-timesheet-modal__submit {
    margin-top: 20px;
    height: 1px;
  }

  .month-picker {
    width: min-content;
    .box {
      width: max-content;
      cursor: pointer;
      label {
        cursor: pointer;
      }
      i {
        cursor: pointer;
        color: black;
        margin-left: 4px;
      }
    }
  }

  .month-picker>.rmp-container.show {
    left: -20px;
  }
}
