@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* TimeLeftOnOrderDetails styling */

.time-left-on-order-container {
    display: grid;
    grid-template-columns: 47.5% 5% 47.5%;
    grid-template-rows: 100%;

    .time-left-on-order-details__item {
        margin: 0px 0px 0px 10px;
        .font14RegularSteel();
    }
    .time-left-on-order-details__title {
        margin: 15px 0px 0px 10px;
        .font16MediumSteel();
    }

    .time-left-on-order-details {
        grid-column: 1;
        grid-row: 1;
        background-color: @ultraLightBackGroundGrey;
        .font14RegularSteel();
    }

    .time-left-on-order-calculated-fields {
        grid-column: 3;
        grid-row: 1;
        background-color: @ultraLightBackGroundGrey;

        .time-left-on-order-calculated-fields-checkbox {
            display:flex;

            height:48px;
            border: 1px solid darken(@backGroundGrey, 0.2);
            background-color: @backGroundGrey;

            .time-left-on-order-calculated-fields-checkbox__checkbox {
                width: 25%;
                display: grid;
                grid-template-columns: 100%;
                grid-template-rows: 50% 50%;

                .time-left-on-order-calculated-fields-checkbox__checkbox-text {
                    margin: auto;
                    grid-row: 1;
                    grid-column: 1;
                    .font16MediumSteel();
                }

                input {
                    margin:auto;
                    grid-row: 2;
                    grid-column: 1;
                    &:hover {
                        cursor: pointer;
                    }
                }
            } 
        }
    }
}