@import "../../../../less/Variables";
@import "../../../../less/Fonts";

.legend {
  .font14RegularDarkSkyBlue();
  width: 65px;
  height: 80px;
  line-height: 80px;
  color: @darkSkyBlue;
  margin-left: 20px;

  .float {
    float: left;
    margin-right: 5px;
    cursor: pointer;
  }

  .legend-border {
    float: left;
    margin-top: -10px;
    position: relative;
    display: flex;
    width: 720px;
    height: 74px;
    line-height: 74px;
    background-color: white;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px @coolGrey;

    &:before, &:after {
      bottom: 100%;
      left: 4%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:before {
      border-bottom-color: @coolGrey;
      border-width: 11px;
      margin-left: -11px;
    }

    &:after {
      border-bottom-color: white;
      border-width: 10px;
      margin-left: -10px;
    }

    .legend-item {
      flex: 1;

      .legend-color {
        margin: 19px 8px;
        width: 30px;
        height: 30px;
        float: left;

        &.holiday {
          border: solid 1px lighten(@coolGrey,15%);
          background-color: @seaFoamBlue;
        }

        &.vacation {
          border: solid 1px lighten(@coolGrey,15%);
          background-color: @paleGreyFour;
        }

        &.weekend {
          border: solid 1px lighten(@coolGrey,15%);
          background-color: @paleGrey;
        }

        &.comment {
          text-align: center;
          color: #f9c681;
          font-size: 8px;
          line-height: 30px;
          margin-right: 5px;
        }
      }
    }

    .legend-description {
      .font14RegularSteel();
    }
  }
}