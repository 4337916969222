@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* ReportingContainer styling */

.title-screen {
    .noSelect();
    width: 1060px;
    margin: 4% auto auto auto;
    padding: 0 0 60px 0;
    border-radius: 7px;
    background-color: @backGroundGrey;

    .title {
        .font46MediumWhite();
        margin: 0 0 60px 0;
        text-transform: uppercase;
        text-align: center;
        background-color: @darkSkyBlue;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
        padding: 30px 0 10px 0;
    }

    .reports-container {
        display: flex;
        .report-box {
            height: 238px;
            width: ~"calc(17% - 20px)";
            background-color: white;
            border-radius: 7px;
            margin: auto;
            text-align: center;
            color: @darkSkyBlue;

            animation: fadein_1 0.75s;

            &:hover {
                cursor: pointer;
                color: darken(@darkSkyBlue, 10%);
            }

            .report-box-title {
                .font20MediumDarkSkyBlue();
                margin: auto;
            }

            .far {
                margin: 20px 0;
                font-size: 120px;
            }
        }
    }
}
