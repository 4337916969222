@import "../../../less/Variables";

/* Header styling */

.topheader {
  height: ~"calc(@{headerHeight}*2)";

  .weekNumber {
    .noSelect();
    flex: 0 0 12px;
    padding-left: 290px;
    padding-right: 242px;
    position: relative;
    margin-top: -12px;
    text-align: center;
    .font10LightSteel();
    text-transform: uppercase;
  }
}

.header {
  height: @headerHeight;
  line-height: @headerHeight;
  display: flex;
  background-color: @darkSkyBlue;

  &.small {
    position: absolute;
    min-width: @appMinWidth;
    width: 100%;
    z-index: 2;
    height: @headerHeightSmall;
    line-height: @headerHeightSmall;
  }
}

@import "identity/Identity";
@import "profile/Profile";
@import "profile/ChangeUserModal";
@import "profile/SettingsModal";
@import "weekend/Weekend";
