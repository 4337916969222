@import "../../../../less/Variables";

/* Timesheet styling */

.timesheet {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@import "TimesheetBorders";
@import "TimesheetNavigation";
@import "TimesheetRow";
@import "TimesheetTotal";
@import "TimesheetFooter";
