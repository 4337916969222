@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* TimesheetComplete styling */

.timesheet-complete {
  .timesheet-complete-submit {
    width: @footerButtonWidth;
    height: @footerButtonHeight;
    margin: 23px 0px 0 0px;
    .font12MediumPaleGreyTwo();

    &:disabled {
      background-color: @darkSkyBlue50;
      transition: none;
      cursor: default;
    }
  }
}
