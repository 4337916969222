@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/KeyFrames";

/* SiteLocation styling */

.site-location {
  .noSelect();
  text-transform: uppercase;
  .font12MediumWhite();
  animation: fadein_1 0.75s;
}
