@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* ProjectTimesheetReport styling */

.project-timesheet-report {
  .noSelect();
  margin: 25px 0 25px 25px;
  width: ~"calc(100% - 25px)";
  display: flex;
  height: 100%;

  .project-selection {
    height: 100%;
    overflow: hidden;
    margin: 0px 25px 0px 0px;
    min-width: 200px;
    width: ~"calc(50% - 25px)";

    .project-selection-title {
      .font18MediumBlack();
      text-align: center;
      line-height: 40px;
    }

    .project-selection-box {
      height: 100%;

      .autosuggest-list {
        label {
          padding-left: 60px
        }
      }

      .selected {
        .font16RegularBlack();
        background-color: darken(@paleGrey,15%);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;

        .fas.fa-times {
          margin: auto 0;
        }

        &:hover {
          cursor: pointer;
          color: @white;
          background-color: @pigPink;
        }
      }
    }
  }

  .project-timesheet-information {
    display: flex;
    flex-direction: column;
    margin: 0px 50px 0px 0px;
    min-width: 670px;
    width: ~"calc(45% - 50px)";
    animation: fadein_1 0.7s;

    .period-picker-container {
      margin: 5px 0px 10px 10px;
      &.info-container {
        label {
          margin-left: -10px;
        }

        .month-year-picker {
          display: flex;
          width: 485px;

           .month-year-picker__button {
             text-align: left;
           }
        }
      }
    }

    .info-container {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      .project-timesheet-clientname,
      .project-timesheet-externalprojectmanager,
      .project-timesheet-ourreference,
      .project-timesheet-yourreference,
      .project-timesheet-contractreference {
        width: 480px;
        height: 34px;
      }

      label {
        display: flex;
        line-height: 24px;

        .project-timesheet-optional {
          .font10LightGray();
          padding-left: 5px;
        }
      }

      .project-timesheet-internalprojectmanager,
      .project-timesheet-companytemplate {
        .react-autosuggest__container {
          .react-autosuggest__input {
            width: 480px;
            height: 35px;
          }
        }
      }

      label {
        flex: 1;
        .font14RegularSteel();
      }

      input[type="text"] {
        display: block;
        resize: none;
        padding: 8px;
        background-color: @white;
        border: solid 1px @silverTwo;
        text-align: center;
        .font14RegularGray();
        transition: border-color ease-in-out 0.15s;
        &:focus {
          border-color: #5cb3fd;
          outline: none;
        }
        &::placeholder {
          color: #636c72;
        }
        &[disabled=''] {
          background-color: @disabledGrey;
        }
      }

      .react-autosuggest__suggestions-container--open {
        min-width: 210px;
        width: 480px;
        max-height: 300px;
        overflow-y: auto;
        .styled-scrollbar();
      }

      .DayPicker-Day--today {
        color: @darkSkyBlue;
      }
      .DayPicker {
        .font14RegularSteel();
      }
      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        color: @white;
        background-color: @darkSkyBlue;
      }
      .DayPickerInput-OverlayWrapper {
        z-index: 9999;
      }
    }

    .project-timesheet-buttons {
      display: flex;
      flex-direction: column;

      .export-button {
        .font14RegularWhite();
        display: block;
        padding: 7px 30px;
        margin-top: 10px;
      }
    }

    .report-export-spinner();
  }
}

@import "AutoSuggestList";
