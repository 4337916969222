@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* AutoSuggestList styling */

.autosuggest-list {
    .noSelect();
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: @ultraLightBackGroundGrey;

    label {
        width: 100%;
        text-align: center;
        background-color: @backGroundGrey;
        border: 1px solid darken(@backGroundGrey, 0.2);
        border-bottom: 0;
        .font19MediumSteel();
        padding: 8px;

        .sorting {
            float: right;
            cursor: pointer;
            color: @steel;

            .fa-sort-up {
                opacity: 0.5;

                &.active {
                    opacity: 1;
                }
            }

            .fa-sort-down {
                opacity: 0.5;

                &.active {
                    opacity: 1;
                }
            }
        }

        .fa-sort {
            float: right;
            cursor: pointer;
        }
    }

    .form-group {
        width: 100%;
        margin-bottom: 0px;
        background-color: @backGroundGrey;
        border: 1px solid darken(@backGroundGrey, 0.2);
        border-bottom: 0;
        border-top: 0;
        min-height: 24px;

        input {
            border: 1px solid darken(@backGroundGrey, 0.2);
            border-left: 0;
            border-right: 0;

            text-align: center;
            width: 100%;
            padding: 5px;
        }
    }

    .extra-options-box {
        margin-bottom: 10px;
        width: 100%;

        .button {
            .noSelect();
            display: block;
            padding: 7px 30px;
            background-color: @darkSkyBlue;
            color: @white;
            cursor: pointer;
            text-align: center;
            text-transform: uppercase;
            .font14RegularWhite();

            &:hover {
                background-color: darken(@darkSkyBlue, 10%);
            }
        }
    }

    .suggestedList {
        width: 98%;
        height: 86%;
        text-align: center;
        overflow: auto;
        // height: 40%;

        .suggestion {
            padding: 8px;
            .font14RegularSteel();

            &:hover {
                cursor: pointer;
                color: @white;
                background-color: @darkSkyBlue;
                opacity: 0.65;
            }
        }

        .styled-scrollbar();
    }

    .scroll-height {
        height: 83%;
    }

    .selectedList {
        margin-bottom: 30px;
        padding-top: 30px;
        width: 100%;
        text-align: center;
        background-color: @backGroundGrey;
        border: 1px solid darken(@backGroundGrey, 0.2);
        border-top: 0;
        max-height: 55%;

        .selected {
            padding: 8px;
            .font14RegularSteel();
            display:flex;

            .fa-times {
                padding-right: 5px;
                visibility: hidden;
            }

            &:hover {
                .noSelect();
                cursor: pointer;
                color: @white;
                background-color: @pigPink;

                .fa-times {
                    visibility: visible;
                }
            }
        }
    }
}
