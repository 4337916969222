@import "../less/Variables";

/* Modal styling */

.modal {
  .noSelect();
}

.modal-forefront {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  width: 480px;
}

.modal-content {
  background-color: @white;
  border-radius: 3px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.3);
}
