@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* TimeLeftOnOrder styling */

.time-left-on-order-report {
  display: grid;  
  margin: 30px;
  width: 100%;
  height: 100%;
  grid-template-columns: 23% 2% 23% 2% 50%;
  grid-template-rows: 7% 93%;

    .time-left-on-order-report-tscode {
      grid-row: 2;
      grid-column: 1; 
    }

    .time-left-on-order-report-preset {
      grid-row: 1;
      grid-column: 1;
    }

    .time-left-on-order-report-company {
      grid-row: 2;
      grid-column: 3; 
      .noSelect();
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: @ultraLightBackGroundGrey;

      .time-left-on-order-report-company-title {
        width: 100%;
        text-align: center;
        background-color: @backGroundGrey;
        border: 1px solid darken(@backGroundGrey, 0.2);
        .font19MediumSteel();
        padding: 8px 8px 23px 8px;
      }

      .time-left-on-order-report-company-options {
        margin-top: 30px;
        width: 100%;
        text-align: center;

        .time-left-on-order-report-company-option {
          padding: 8px;
          .font14RegularSteel();
          
          &:hover {
            cursor: pointer;
            color: @white;
            background-color: @darkSkyBlue;
            opacity: 0.65;
          }
        }
        
        .selected {
          color: @white;
          background-color: @darkSkyBlue;
        }
      }
    }
    
    .time-left-on-order-container {
      grid-row: 2;
      grid-column: 5;
    }
}


@import "AutoSuggestList";
@import "TimeLeftOnOrderDetails";
