@import "../../../../less/Fonts";
@import "../../../../less/Variables";

/* Weekend styling */

.weekend-box {
  display: flex;
  align-items: center;
  padding-right: 4px;

  label {
    .font13RegularWhite();
  }

  .custom-checkbox {
    user-select: none;
    height: 12px;
    width: 12px;
    border-radius: 1px;
    font-size: 13px;
    background-color: @white;
    color: @darkSkyBlue;
    text-align: center;
    line-height: 13px;
    margin: 0 8px 0 0;
  }
}
