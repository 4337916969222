@keyframes fadein_07 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@keyframes fadein_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeinout_09 {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
}