@import "../less/Fonts";

/* IncompleteMonthsToast styling */

.incomplete-months-message {
  display: block;
}

.go-to-month-button {
  width: 80%;

  margin: 15px 20px 4px 0;
}