@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* TimesheetCompleteModal styling */
.timesheet-complete {
  .closable-modal {
    .timesheet-complete-month-year {
      margin-bottom: 20px;
      .font14RegularGunmetal();
    }

    .timesheet-complete-attention {
      margin-bottom: 20px;
      .font14MediumWarmPink();
    }

    .timesheet-complete-message {
      margin-bottom: 50px;
      .font14RegularWarmPink();
    }

    .timesheet-complete-line {
      position: relative;

      .line {
        height: 0;
        position: absolute;
        left: -20px;
        border: solid 1px @paleGreyThree;
        width: @modalDefaultWidth;
      }
    }

    .timesheet-complete-button-container {
      .timesheet-complete-complete {
        margin-top: 20px;
      }

      .timesheet-complete-cancel {
        margin-top: 18px;
        margin-right: 15px;
        padding: 10px 0 10px 10px;
        float: right;
        .font12MediumSteel();

        &:hover {
          cursor: pointer;
          color: @gunmetal;
        }
      }

      .clearFix();
    }
  }
}
