@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* ManageClientTimesheet styling */

.manage-client-timesheet {
  .manage-client-timesheet-submit {
    .font12MediumSteel();
    width: auto;
    height: @footerButtonHeight;
    margin: 23px 0px 0 0px;
    padding: 0 20px;
  }

  .modal-dialog {
    width: 600px;
  }
}
