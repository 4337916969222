@import "../../../less/Fonts";

/* Common styling */

.alert {
  .font12RegularBlack();
  padding: 8px 16px;
  border: 1px solid transparent;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 1px;
  background-color: white;
  border: solid 1px @coolGrey;
  vertical-align: middle;

  &:checked {
    background-color: @darkSkyBlue;
    outline: none;

    &::after {
      position: relative;
      top: -4px;
      left: 1px;
      content: '\f00c';
      font: normal normal normal 14px/1 FontAwesomeSolid;
      font-size: 8px;
      color: white;
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px fade(@darkSkyBlue, 50%);
    outline: none;

    &.inverse {
      box-shadow: 0 0 0 2px fade(@coolGrey, 50%);
    }
  }
}

input[type="submit"] {
  .font12MediumWhite();
  padding: 0 30px;
  height: 30px;
  border: none;
  border-radius: 50px;
  background-color: @darkSkyBlue;
  text-align: center;
  transition: all ease-in-out 0.2s;

  &.inverse {
    color: @steel;
    border: solid 1px @coolGrey;
    background-color: white;
  }

  &:hover, &:active {
    background-color: darken(@darkSkyBlue, 10%);

    &.inverse {
      color: @white;
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px fade(@darkSkyBlue, 50%);
    outline: none;

    &.inverse {
      box-shadow: 0 0 0 2px fade(@coolGrey, 50%);
    }
  }
}

div[role="submit"] {
  .font12MediumSteel();
  text-align: center;
  padding: 8px;

  &:hover {
    color: @gunmetal;
  }
}

input[type="submit"], div[role="submit"] {
  text-transform: uppercase;
  margin-left: 8px;
  cursor: pointer;
  float: right;
}

input[type="submit"][disabled=''] {
  cursor: default;
  color: @white;
  background-color: @darkSkyBlue50;
  transition: none;
}

@import "../../../common/TextInput";
@import "../../../common/Modal";
@import "../../../common/ClosableModal";
@import "../../../common/AreYouSureModal";
@import "../../../common/Toggle";
@import "../../../common/CookieConsent";
@import "../../../common/ErrorBoundary";
@import "../../../common/EmptyTimesheetToast";
@import "../../../common/Preset";
@import "WeekPicker";
@import "DatePicker";
@import "DateRangePicker";
@import "MonthYearPicker";