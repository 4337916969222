@import "../../../../less/Variables";
@import "../../../../less/Fonts";

.switch-month {
  display: flex;
  width: @timesheetCodeWidth - 60px;
  height: 40px;
  margin: 0 30px;
  cursor: pointer;

  .month-picker-box-container .month-year-picker__button {
    font-weight: 500;
    text-align: center;
  }

  .month-picker-box-container:hover .month-year-picker__button  {
    color: white;
  }

  .switch-month-select {
    width: 35px;
    height: 40px;
    border: solid 1px @coolGrey;
    outline: 0;
    background: white;
    .font24MediumSteel();
    line-height: 1.5;
    text-align: center;

    &.switch-month-select-left {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }

    &.switch-month-select-right {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }

    &:hover {
      color: white;
      background-color: @darkSkyBlue;
      transition: all ease-in-out 0.2s;
      cursor: pointer;
    }
  }

  .month-picker-box-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 165px;
    border-top: solid 1px @coolGrey;
    border-bottom: solid 1px @coolGrey;
    .font14MediumSteel();
    line-height: 1.5;

    .month-picker {
      .box {
        label {
          cursor: pointer;
        }
      }
    }

    &:hover {
      color: white;
      background-color: @darkSkyBlue;
      transition: all ease-in-out 0.2s;
      cursor: pointer;
    }
  }

  &.switch-month-weekly {
    .month-picker-box-container {
      width: 235px;
      height: 40px;
      border-left: solid 1px @coolGrey;
      border-right: solid 1px @coolGrey;
      border-radius: 25px;
    }

    .month-picker>.rmp-container .rmp-popup.light {
      left: 35px;
    }
  }
}
