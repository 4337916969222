@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* SearchInvoiceNumber styling */

.search-invoicenumber {
  width: 100%;
  margin: 0 10px;
  display: flex;
  align-items: center;
  height: 60px;

  &:hover {
    cursor: default;
  }

  .react-autosuggest__suggestions-container {
    width: 258px;
  }

  .search-invoicenumber__autosuggest {
    width: 258px;
  }

  .search-invoicenumber__submit {
    margin-left: 8px;
    width: 30px;
    height: 30px;
    float: right;
    vertical-align: middle;
    background-color: @darkSkyBlue;
    color: white;
    outline: none;
    border: none;

    &[disabled=''] {
      cursor: default;
      transition: none;
      color: darken(@disabledGrey, 25);
      background-color: @disabledGrey;

      &:hover {
        cursor: default;
        background-color: @disabledGrey;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: @windowsBlue;
    }
  }
}
