
/* Digital Signing WizardScreen1 Styling*/

.wizard-screen-1 {
    min-height: 480px;

    .hours-per-week-toggle {
      display: flex;
      label {
        margin: 0 10px 0 0;
        line-height: 27px;
      }
    }

    .timesheetcodes-container {
        height: 240px;
        display: block;
        overflow: hidden;

        .scrollBarContainer {
          height: 200px;
        }

        &.readonly {
          input[type="checkbox"] {
            &:checked {
              background-color: @checkboxReadonly;
            }
          }
        }

        .tscode-select {
          display: inline-flex;
          width: 98%;
          box-sizing: content-box;
          padding: 5px 0 5px 0;

          .tscode-select-checkbox {
            float: left;
            padding-left: 10px;
            margin-top: -2px;
          }

          .tscode-select-name {
            .font14RegularGunmetal();
          }

          :hover {
            cursor: pointer;
          }
        }
      }

      .digital-signing-modal-selectAll {
        line-height: 20px;
        margin-bottom: 28px;

        .digital-signing-modal-selectAll-checkbox {
          float: left;
        }

        .digital-signing-modal-selectAll-checkbox-text {
          float: left;
          .font12RegularDarkSkyBlue();
        }

        :hover {
          cursor: pointer;
        }
      }
}