@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* ActualsReport styling */

.actuals-selection-container {
  width: 100%;
  height: 100%;

  .preset.actualsreport {
    justify-content: flex-end;
    margin-right: 26px;
  }
}

.actuals-report {
  margin: 25px 0 25px 25px;
  width: 100%;
  display: flex;

  .actuals-information {
    flex: 0 0 20%;
    display: flex;
    flex-direction: column;

    .dates-error {
      margin-bottom: 10px;
      padding: 5px;
      width: 100%;
      .font14RegularWhite();
      background-color: @pigPink;
    }

    .hidden {
      visibility: hidden;
      display: none;
    }

    .info-container {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      label {
        flex: 1;
        .font14RegularSteel();
      }

      input {
        display: block;
        resize: none;
        padding: 8px;
        user-select: none;
        background-color: @white;
        border: solid 1px @silverTwo;
        text-align: center;
        .font14RegularGray();
        transition: border-color ease-in-out 0.15s;
        &:focus {
          border-color: #5cb3fd;
          outline: none;
        }
        &::placeholder {
          color: lighten(#636c72, 40%);
        }
        &:disabled,
        :read-only {
          background-color: @disabledGrey;
        }
      }

      .DayPicker-Day--today {
        color: @darkSkyBlue;
      }
      .DayPicker {
        .font14RegularSteel();
      }
      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        color: @white;
        background-color: @darkSkyBlue;
      }
      .DayPickerInput-OverlayWrapper {
        z-index: 9999;
      }
    }

    .actuals-buttons {
      .noSelect();
      display: flex;
      flex-direction: column;

      .export-button {
        display: block;
        padding: 7px 30px;
        margin-top: 10px;
        .font14RegularWhite();
      }
    }

    .report-export-spinner();
  }

  .actuals-selection {
    flex: 1;
    display: flex;
    height: 100%;

    .actuals-selection-box {
      flex: 0 0 30%;
      margin: 0 auto;
    }
  }
}

@import "AutoSuggestList";
