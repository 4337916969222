@import "../../../less/Variables.less";
@import "../../../less/Fonts";
@import "../../../less/Mixins";

/* DatePicker styling */

.date-picker {
   .date-picker-input-container {
        display: flex;

        .date-picker-input-container-input {
            outline: none;
        }

        .date-picker-input-container-button {
            color: @darkSkyBlue;
            width: 30px;
            outline: none;
            border-left: none;
            border-top: solid 1px @silverTwo;
            border-right: solid 1px @silverTwo;
            border-bottom: solid 1px @silverTwo;

            &:hover {
                cursor: pointer;
                color: darken(@darkSkyBlue, 5%);
            }
        }
   }

  .dialog-sheet {
    background: white;
    z-index: 9999;

    .rdp-caption_label {
        .noSelect();
        .font16MediumSteel();
    }
  }

  .rdp-root {
    --rdp-accent-color: transparent;
    --rdp-today-color: @darkSkyBlue;
    --rdp-background-color: black;
    --rdp-day-height: 30px;
    --rdp-day-width: 30px;
    --rdp-day-font: 12px var(--rdp-font-family);
    --rdp-font-family: Rubik, sans-serif;
    --rdp-weekday-text-transform: uppercase;
    --rdp-weekday-opacity: 40%;
    --rdp-weekday-font: 400 12px var(--rdp-font-family);
    --rdp-outside-opacity: 20%;
    --rdp-selected-font: 500 12px var(--rdp-font-family);
    --rdp-week_number-font: 500 12px var(--rdp-font-family) ;
    --rdp-week_number-opacity: 60%;
    --rdp-weekday-padding: 5px 5px;

    .rdp-day:not(.rdp-today) {
        color: @steel;

        &:hover {
            background-color: #e7edff;
        }
    }

    .rdp-selected {
        .rdp-day_button {
            color: black;

            &:hover {
                background-color: #e7edff;
            }
        }

        &:hover {
            background-color: transparent;
        }
    }

    .rdp-chevron {
        fill: black;
    }

    .rdp-selected {
        background-color: @silver;
        border-radius: 100%;

        &:focus-visible {
            outline: none;
        }
    }
  }
}
