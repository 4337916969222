@import "../../../../less/Fonts";
@import "../../../../less/Variables";

/* TimesheetCodeDay styling */

.timesheet-days-weekly {
  .day-hours-container {
    .day-hours {
      border-left: 0;
    }
  }
}

.timesheet-code-days {
  flex:1;
  display: flex;

  .day-hours-container {
    position: relative;
    flex: 1;

    &.editable {
      cursor: text;

      .hours-input {
        &:hover, &.active {
          border: @activeBorder;
        }
      }
    }

    .hours-input-fixed() {
      &.vacation {
        background-color: @paleGreyFour;
      }

      &.readonly {
        background-color: @seaFoamBlue;
        color: @uglyBlueTwo;
      }

      &.weekend {
        background-color: @paleGrey;
      }
    }

    .hours-input-flexible() {
      &.active-row-level {
        background-color: @paleGrey;
      }

      &.active {
        background-color: @white;
      }

      &.multi-selected {
        border: 0;
        border-top: @multiSelectBorder;
        border-bottom: @multiSelectBorder;
        box-shadow: 0 0 6px 0 rgba(68, 183, 222, 0.25);

        &.left-border {
          border-left: @multiSelectBorder;
        }

        &.right-border {
          border-right: @multiSelectBorder;
        }
      }

      &.change-requested {
        background-color: @seaFoamBlue;
        color: @uglyBlueTwo;
      }
    }

    .day-hours {
      position: relative;
      height: @timesheetRowHeight;
      .hours-input-fixed();
      .hours-input {
        height: @timesheetRowHeight - 1;
        width: 100%;
        border: 0;
        outline: 0;
        background: 0;
        .font15MediumGunmetal();
        text-align: center;

        .hours-input-fixed();

        &.extra {
          color: @darkSkyBlue;
        }

        .hours-input-flexible();
      }

      .comment-indicator {
        display: block;
        width: 100%;
        pointer-events: none;
        color: #f9c681;
        font-size: 8px;
        text-align: center;
        position: absolute;
        top: 10%;
      }
    }

    .day-hours-weekly {
      height: @timesheetRowHeight;
      line-height: @timesheetRowHeight;
      text-align: center;
      position: relative;

      .hours-input {
        height: 35px;
        width: @weeklyInputWidth;
        border: solid 1px @silver;
        outline: 0;
        background: 0;
        .font15MediumGunmetal();
        text-align: center;

        .hours-input-fixed();

        &.extra {
          color: @darkSkyBlue;
        }

        .hours-input-flexible();
      }

      .comment-indicator {
        display: block;
        width: 50%;
        pointer-events: none;
        color: #f9c681;
        font-size: 8px;
        text-align: center;
        position: absolute;
        bottom: 41%;
      }
    }

    .day-hours-extra {
      height: @timesheetRowHeight * 2;

      .hours-input {
        height: @timesheetRowHeight / 2;
        width: 100%;
        border: 0;
        outline: 0;
        background: 0;
        .font15RegularGunmetal();
        text-align: center;

        .hours-input-fixed();
        .hours-input-flexible();
      }
    }

    .day-hours-extra-weekly {
      height: @timesheetRowHeight * 2;
      display: flex;
      flex-direction: column;
      align-items: center;

      .hours-input {
        height: 25px;
        width: @weeklyInputWidth;
        margin: 2px 0;
        border: solid 1px @silver;
        outline: 0;
        background: 0;
        .font15MediumGunmetal();
        text-align: center;

        .hours-input-fixed();
        .hours-input-flexible();
      }
    }

    .actions {
      width: 100%;
      padding-left: 1px;
      position: absolute;
      bottom: -11px;

      div {
        display: flex;
        position: relative;
        z-index: 100;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);

        .add-comment, .add-extra-hours {
          flex: 1;
          cursor: pointer;
          background: @darkSkyBlue;
          color: @white;
          text-align: center;
          font-size: 10px;
          line-height: 20px;
          transition: background-color ease-in-out 0.2s;

          &:hover {
            background-color: darken(@darkSkyBlue, 10%);
          }
        }

        .add-extra-hours {
          border-left: solid 1px @white;
        }
      }
    }

    .actions-weekly {
      width: 100%;
      padding-left: 1px;
      position: absolute;
      bottom: -9px;

      div {
        display: flex;
        position: relative;
        z-index: 100;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
        width: @weeklyInputWidth;
        margin: 0 auto;

        .add-comment, .add-extra-hours {
          flex: 1;
          cursor: pointer;
          background: @darkSkyBlue;
          color: @white;
          text-align: center;
          font-size: 10px;
          line-height: 20px;
          transition: background-color ease-in-out 0.2s;

          &:hover {
            background-color: darken(@darkSkyBlue, 10%);
          }
        }

        .add-extra-hours {
          border-left: solid 1px @white;
        }
      }
    }

    .actions-extra {
      width: 100%;
      height: 20px;

      div {
        display: flex;
        position: relative;
        z-index: 1;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);

        .add-comment {
          flex: 1;
          cursor: pointer;
          background: @darkSkyBlue;
          color: @white;
          text-align: center;
          font-size: 10px;
          line-height: 20px;
          transition: background-color ease-in-out 0.2s;

          &:hover {
            background-color: darken(@darkSkyBlue, 10%);
          }
        }
      }

      .hours-input-fixed();
    }

    .actions-extra-weekly {
      width: @weeklyInputWidth;
      height: 20px;
      margin: 0 auto;

      div {
        display: flex;
        position: relative;
        z-index: 1;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);

        .add-comment {
          flex: 1;
          cursor: pointer;
          background: @darkSkyBlue;
          color: @white;
          text-align: center;
          font-size: 10px;
          line-height: 20px;
          transition: background-color ease-in-out 0.2s;

          &:hover {
            background-color: darken(@darkSkyBlue, 10%);
          }
        }
      }
    }
  }

  .weekend-collapsed {
    flex: 0 0 1.5px;
    background-color: @silver;
  }
}

.tooltip-data-content[data-content] {
  .font12RegularWhite();
  display: block;
  position: relative;
  opacity: 0.9;

  &:hover {
    text-decoration: none;
    z-index: 99;

    &:after {
      content: attr(data-content);
      display: block;
      background: @charcoalGrey;
      border-radius: 4px;
      line-height: 28px;
      padding: 0 10px;
      width: 250px;
      max-width: 250px;
      word-wrap: break-word;

      right: 49px;
      top: 13px;
      position: absolute;
      z-index: 98;
    }
  }
}

.tooltip-data-content-weekly[data-content] {
  .font12RegularWhite();
  display: block;
  position: relative;
  opacity: 0.9;

  &:hover {
    text-decoration: none;
    z-index: 99;

    &:after {
      content: attr(data-content);
      display: block;
      background: @charcoalGrey;
      border-radius: 4px;
      line-height: 28px;
      padding: 0 10px;
      width: 250px;
      max-width: 250px;
      word-wrap: break-word;

      right: 170px;
      top: 13px;
      position: absolute;
      z-index: 98;
    }
  }
}

@import "TimesheetCodeDayAddHoursComment";
