@import "../../../../less/Variables";

/* TimesheetRow styling */

.timesheet-row {
  flex: 0 0 @timesheetRowHeight;
  display: flex;
}

.timesheet-row-expanded {
  flex: 0 0 @timesheetRowExpandedHeight;
  display: flex;
}

.timesheet-row-filler-left {
  width: @timesheetWeeklyHiddenWidth;
}
.timesheet-row-filler-right {
  width: @timesheetWeeklyHiddenWidth;
}

@import "TimesheetCode";
@import "TimesheetCodeDay";
@import "TimesheetCodeTotal";
@import "SearchTimesheetCode";
