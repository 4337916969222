@import "../../../../less/Fonts";

/* Version styling */

.build-info {
  position: absolute;
  bottom: 2px;
  right: 4px;
  .font12RegularSteel();
}
