@import "../less/Variables";
@import "../less/Fonts";

/* TextInput styling */

.form-group {
  margin-bottom: 19px;
  overflow: hidden;

  label {
    display: inline-block;
    margin-bottom: 10px;
    .font14RegularSteel();
    transition: color ease-in-out 0.15s;

    &.has-extra {
      color: @gunmetal;

      span {
        font-size: 12px;
        color: @steel;
        transition: color ease-in-out 0.15s;
      }
    }

    &.required {
      &:after {
        content: '*';
        margin-left: 5px;
      }
    }
  }

  .form-control {
    display: block;
    resize: none;
    width: 100%;
    padding: 8px;
    background-color: @white;
    border: solid 1px @silverTwo;
    .font14RegularGray();
    transition: border-color ease-in-out 0.15s;

    &:focus {
      border-color: #5cb3fd;
      outline: none;
    }

    &::placeholder {
      color: #636c7271;
    }

    &:disabled, &:read-only {
      background-color: @disabledGrey;
    }
  }

  &.has-error {
    label {
      color: @pigPink;

      span {
        color: @pigPink;
      }
    }

    .form-control {
      border-color: @pigPink;
    }
  }
}
