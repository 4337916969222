@import "../../../../less/Fonts";
@import "../../../../less/Variables";

/* TimesheetNavigation styling */

.timesheet-navigation {
  flex: 0 0 @headerHeight;
  display: flex;
  align-items: center;

  &.small {
    position: absolute;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
    background-color: white;
    height: @headerHeightSmall;

    @media only screen and (min-width: 1440px) {
      position: fixed;
      width: 100%;
      top: @headerHeightSmall;
    }
  }

  .dayHighlight() {
    &.day-highlight {
      color: @darkSkyBlue;
    }
  }

  .timesheet-navigation-weekly {
    height: @headerHeight;
    line-height: @headerHeight;
    width: @timesheetWeeklyHiddenWidth;
    text-align: center;
    font-size: 27px;
    color: @steel;
    &:hover {
      color: @darkSkyBlue;
      cursor: pointer;
    }
  }

  .month-navigation-width {
    width: ~"calc(100% - (@{timesheetCodeWidth} + @{timesheetCodeTotalWidth}))";
  }
  .week-navigation-width {
    width: ~"calc(100% - (@{timesheetCodeWidth} + @{timesheetCodeTotalWidth} + (@{timesheetWeeklyHiddenWidth} * 2)))";
  }

  .timesheet-navigation-days {
    .noSelect();
    display: flex;
    align-items: center;
    min-width: 908px;

    .day {
      flex: 1;

      .day-week {
        line-height: 2;
        text-align: center;
        text-transform: uppercase;
        .font12LightSteel();
        .dayHighlight();
      }

      .day-month {
        .font16MediumSteel();
        line-height: 1.5;
        text-align: center;
        .dayHighlight();
      }

      &.day-highlight-border {
        margin-bottom: -3px;
        border-bottom: 3px solid @darkSkyBlue;
      }
    }
  }

  .weekend-collapsed {
    flex: 0 0 2px;
    height: @headerHeight;
  }
}

@import "SwitchMonth";
@import "MonthWeekNavigation";
