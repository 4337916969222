@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";


/* ImportActualsTemplateLegend styling */

.import-actuals-template-legend {
  margin-left: 20px;
  margin-bottom: 5px;
  .noSelect();
  .font12RegularBlack();

  .float {
    float: left;
    margin-right: 5px;
    cursor: pointer;
  }

  .import-actuals-template {
    margin-top: 20px;
    width: 1100px;

    .import-actuals-template-row {
      display: flex;
      line-height: 20px;
      text-align: center;

      &.placeholder {
        margin-top: 20px;
      }

      &.rowheader {
        .font12RegularWhite();
        background-color: @excelGreen;
      }

      .import-actuals-template-cell {
        width: 110px;
        border: 1px solid @black10;

        &.tscode {
          width: 130px;
        }

        &.required {
          .font12MediumBlack();
        }

        &.optional {
          color: @coolGrey;
        }

        &.noborder {
          border: 0;
        }

        &.excel-logo {
          cursor: pointer;
          margin-left: -30px;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

@import "ImportActualsRow";
