@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* ExportMenu styling */

.export-menu {
  .noSelect();

  .export-menu__export-menu_dropdown {
    float: right;

    .export-menu__export-menu_dropdown__export-menu-submit {
      .font12MediumSteel();
      width: @footerButtonWidth;
      height: @footerButtonHeight;
      margin: 23px 0px 0 0px;

      &:hover{
        color: white;
      }

      &:disabled {
        cursor: default;
        color: @steel;
        background-color: @coolGrey;
        transition: none;
      }

      &:disabled:hover {
        color: @steel;
        background-color: @coolGrey;
        transition: none;
      }
    }

    .export-menu__export-menu_dropdown__export-menu-submit--open {
      border-radius: 0px 0px 5px 5px;
      border-top: 0px;
      color: white;
      background-color: @darkSkyBlue;
      box-shadow: none;
      transition: all 0.15s ease-in, border-top-left-radius 0.2s, border-top-right-radius 0.2s;
    }

    .export-menu__export-menu_dropdown__container {
      .font12MediumSteel();
      position: relative;
      top: 15px;
      width: 100%;
      line-height: 45px;
      margin-top: -120px;
      text-align: center;
      background-color: white;
      padding: 0 5px;

      .export-menu__export-menu_dropdown__container__item {
        cursor: pointer;
        border: 1px solid @steel;
        border-radius: 50px;
        margin-top: 12px;

        &:hover {
          color:white;
          background-color: @darkSkyBlue;
        }
      }
    }

    .export-menu__export-menu_dropdown__container--open {
      border: 1px solid @coolGrey;
      border-bottom: 0px;
      padding-bottom: 10px;
      border-radius: 5px 5px 0px 0px;
    }
  }
}

@import '../../common/DropDown';