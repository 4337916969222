@import "../../../../less/Variables";

/* TimesheetTotal styling */

.timesheet-total {
  .timesheet-code-container {
    .timesheet-code {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .timesheet-row-filler-left, .timesheet-row-filler-right {
    background-color: @paler;
  }

  .timesheet-code-days {
    .day-hours-container {
      .day-hours {
        background-color: @paler;
        color: @steel;
      }
    }
  }

  .timesheet-code-total-container {
    .timesheet-code-total {
      flex: 1;
      background-color: @pale;
    }
  }
}
