@import "../../../less/Variables.less";
@import "../../../less/Fonts";
@import "../../../less/Mixins";

/* DateRangePicker styling */

.date-range-picker {
    width: 300px;
    // .rdp {
    //     --rdp-cell-size: 30px;
    //     --rdp-outline: 1px solid @gunmetal;
    // }

   .date-range-picker-input-container {
        display: flex;

        .date-range-picker-input-container-input {
            outline: none;
        }

        .date-range-picker-input-container-button {
            color: @darkSkyBlue;
            width: 30px;
            outline: none;
            border-left: none;
            border-top: solid 1px @silverTwo;
            border-right: solid 1px @silverTwo;
            border-bottom: solid 1px @silverTwo;

            &:hover {
                cursor: pointer;
                color: darken(@darkSkyBlue, 5%);
            }
        }
   }

   .dialog-sheet {
    background: white;
    z-index: 9999;

    .rdp-caption_label {
        .noSelect();
        .font16MediumSteel();
    }
  }

//   .dialog-sheet {
//     z-index: 9999;
//     background: white;

//     .rdp-caption_label {
//         .font16MediumSteel();
//     }

//     .rdp-head_cell {
//         .font12LightSteel();
//     }

//     .rdp-weeknumber {
//         .font12MediumSteel();

//         &:after {
//             content:"";
//             position:absolute;
//             width: 30px;
//             height: 30px;
//             border-right: 1px solid lighten(@steel,35%);
//         }
//     }

//     .rdp-day {
//         .font12LightSteel();
//     }

//     .rdp-day_selected {
//         font-weight: 500;
//         color: black;
//         background-color: @silver;

//         &:focus-visible {
//             outline: none;
//         }
//     }

//     .rdp-day_today {
//         color: @darkSkyBlue;
//     }
//   }
    .rdp-root {
        --rdp-accent-color: transparent;
        --rdp-today-color: @darkSkyBlue;
        --rdp-background-color: black;
        --rdp-day-height: 30px;
        --rdp-day-width: 30px;
        --rdp-day-font: 12px var(--rdp-font-family);
        --rdp-font-family: Rubik, sans-serif;
        --rdp-weekday-text-transform: uppercase;
        --rdp-weekday-opacity: 40%;
        --rdp-weekday-font: 400 12px var(--rdp-font-family);
        --rdp-outside-opacity: 20%;
        --rdp-selected-font: 500 12px var(--rdp-font-family);
        --rdp-week_number-font: 500 12px var(--rdp-font-family) ;
        --rdp-week_number-opacity: 60%;
        --rdp-weekday-padding: 5px 5px;
        --rdp-range_middle-font: 500 12px var(--rdp-font-family);
        --rdp-range_start-background: @steel;
        --rdp-range_end-background: @steel;

        .rdp-day:not(.rdp-today) {
            color: @steel;

            &:hover {
                background-color: #e7edff;
            }
        }

        .rdp-selected:not(.rdp-today) {
            .rdp-day_button {
                color: black;

                &:hover {
                    background-color: #e7edff;
                }
            }

            &:hover {
                background-color: transparent;
            }
        }

        .rdp-selected {
            background-color: @silver;

            &:focus-visible {
                outline: none;
            }
        }

        .rdp-day {
            &.rdp-range_start {
                border-top-left-radius: 100%;
                border-bottom-left-radius: 100%;
            }

            &.rdp-range_end {
                border-top-right-radius: 100%;
                border-bottom-right-radius: 100%;
            }
        }

        .rdp-chevron {
            fill: black;
        }
    }
}