@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

.search-timesheetcode {
  width: @timesheetCodeWidth;
  background-color: @paleGrey;
  display: flex;
  align-items: center;
  height: @timesheetRowHeight;
  border-top: 1px solid @silver;

  &:hover {
    cursor: default;
  }

  .timesheet-code {
    height: @timesheetRowHeight;
    overflow: hidden;
    padding-left: 30px;
    .font14RegularGunmetal();
    line-height: @timesheetRowHeight;
    flex: 1;
  }
}

.timesheet-code-flex {
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  align-items: center;
}

.search-timesheet-code-input {
  width: 210px;
  height: 35px;
}

.search-timesheet-code-button {
    background: transparent;
    align-content: center;
    font-size: 18px;
    color: @steel;
    line-height: 18px;
    border: none;
    outline: 0;
    padding-left: 2px;
    padding-right: 2px;

    &:hover {
      color: @darkSkyBlue;
      transition: all ease-in-out 0.2s;
      cursor: pointer
    }
}

.add-timesheet-code-button {
    background: transparent;
    align-content: center;
    font-size: 18px;
    color: @steel;
    line-height: 18px;
    border: none;
    outline: 0;
    padding-left: 2px;
    padding-right: 2px;

    &:hover {
      color: @darkSkyBlue;
      transition: all ease-in-out 0.2s;
      cursor: pointer
    }
}

.react-autosuggest__input {
  width: 210px;
  height: 30px;
  .font12RegularBlack();
  padding: 8px 16px;
  background-color: @white;
  border: solid 1px @silverTwo;
  transition: border-color ease-in-out 0.15s;

  &:focus {
    border-color: #5cb3fd;
    outline: none;
  }
}

.react-autosuggest__suggestions-container--open {
  .clearFix();
  position: absolute;
  background-color: @white;
  z-index: 1050;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
}

.react-autosuggest__suggestions-list {
  padding: 0;
  margin-left: 5px;
  margin-right: 5px;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  line-height: 40px;
  .font12RegularBlack();
}

.react-autosuggest__suggestion--highlighted {
  color: @white;
  background-color: @darkSkyBlue;
}

@import "AddTimesheetCodeModal";
