
/* Digital Signing WizardScreen2 Styling*/

.wizard-screen-2 {
    min-height: 480px;

    .form-group {
        .extra-top-margin {
            margin-top: 15px;
        }
    }
}