@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* InvoiceViewer styling */

.invoice-viewer {
  .noSelect();
  width: 100%;
  height: ~"calc(100vh - 85px)";
  display: flex;

  input[type="text"] {
    .font14RegularGray();
    height: 30px;
    padding: 8px;
    background-color: @white;
    border: solid 1px @silverTwo;
    transition: border-color ease-in-out 0.15s;
    width: 100%;

    &:focus {
      border-color: #5cb3fd;
      outline: none;
    }

    &::placeholder {
      color: #636c7271;
    }

    &[disabled=''] {
      background-color: @disabledGrey;
      cursor: default;
    }
  }

  .invoice-viewer__actions-container {
    display: flex;
    width: 316px;

    .invoice-viewer__actions {
      margin: 10px 0px 8px 4px;
    }
  }

  .invoice-viewer__selection-container {
    display: flex;
    height: ~"calc(100% - 173px)";

    .invoice-viewer__period-container {
      .invoice-viewer__period-container__title {
        .font16RegularSteel();
        margin-left: 10px;
      }

      .invoice-viewer__period-container__picker {
        margin: 5px 0px 10px 10px;
      }
    }

    .invoice-viewer__title-container {
      height: 30px;
      line-height: 30px;
      .font19MediumWhite();
      background-color: @darkSkyBlue;
      margin: 0 10px;
      text-align: center;
      display: flex;

      .invoice-viewer__title {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
      }

      .invoice-viewer__filter__input {
        visibility: hidden;
        width: 100%;
        margin-left: -250px;
        margin-right: 10px;
        margin-top: 4px;

        input {
          .font14RegularGray();
          border: solid 1px @silverTwo;
          height: 20px;
        }
      }

      .invoice-viewer__filter {
        position: relative;
        top: 1px;
        left: -5px;
        width: 25px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .invoice-viewer__supplier-list {
      width: 300px;
      height: 100%;
      border: 1px solid darken(@backGroundGrey, 0.2);
      margin: 0 10px;

      .invoice-viewer__supplier-list-item {
        .list-item();
        height: 40px;
        line-height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.disabled {
          cursor: default;
          background-color: darken(@backGroundGrey, 40%);
        }

        &.invoice-viewer__supplier-list-item--selected {
          font-weight: 500;
          background-color: @darkSkyBlue50;
        }
      }

      .invoice-viewer__supplier-list--scroll-height {
        height: 94%;
      }

      .styled-scrollbar();

      input[type="text"] {
        width: 100%;
      }
    }
  }
}

.list-item() {
  .font14RegularBlack();
  color: @charcoal-grey-85;

  width: auto;
  background-color: lighten(@backGroundGrey, 10%);
  padding: 0 5px;

  &:hover {
    cursor: pointer;
    background-color: lighten(@darkSkyBlue, 20%);
  }
}

@import "SearchInvoiceNumber";
