@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/KeyFrames";

/* Profile styling */

.profileDropDownFlexer {
  .noSelect();
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  min-width: 222px;

  .profile {
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: @darkSkyBlue;
    .font20LightWhite();
    text-align: right;
    flex: 0 0 auto;
    cursor: pointer;

    .profileName {
      width: 100%;
      text-align: center;
      overflow: hidden;
      margin: 0 20px;
      cursor: pointer;
    }

    .avatar {
      min-width: 36px;
      min-height: 36px;
      background-color: @white50;
      border-radius: percentage(1);
      cursor: pointer;

      .avatarFilling {
        .font14MediumWhite();
        line-height: 37px;
        text-align: center;
        cursor: pointer;
      }
    }
    &.clicked {
      background-color: @windowsBlue;
    }
    &:hover {
      background-color: @windowsBlue;
    }
  }

  .profile-backdrop {
    position: fixed;
    top: @headerHeight;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.7;
    z-index: 1049;
    animation: fadein_07 0.75s;

    &.small {
      top: @headerHeightSmall;
    }
  }

  .profileMenu {
    .noSelect();
    background-color: @white;
    box-shadow: 0 3px 7px 0 @black10;
    z-index: 1050;
    cursor: pointer;
    animation: fadein_1 0.5s;

    .profileMenuItem {
      .font12MediumLightBlue();
      padding-left: 20px;
      line-height: 38.25px;

      &:hover {
        color: @windowsBlue;
      }
    }

    .profileMenuItem_help {
      display: flex;
      position: relative;

      .fas {
        &.fa-caret-right {
          padding: 0 8px;
          position: absolute;
          right: 15px;
          line-height: 38.25px;
          font-size: 16px;
        }
      }

      &:hover {
        color: @lightBlue;

        > .helpmenu {
          visibility: visible
        }
      }

      .helpmenu {
        position: absolute;
        left: -50px;
        width: 60px;
        height: 80px;
        visibility: hidden;
        background-color: @white;
        box-shadow: 0 3px 7px 0 @black40;

        .helpmenu_helpmenuitem {
          padding-left: 20px;
          
          &:hover {
            color: @windowsBlue;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@bounce: ease-in;
@bounce-lg: ease-in;
@duration: 0.15s;
@size: 16px;

.checkbox {
  width: @size;
  height: @size;
  cursor: pointer;
  border-radius: @size;
  display: block;
  background-color: lighten(@darkSkyBlue, 30%);
  margin-top: 5px;
  transition: all @duration @bounce;

  &:hover {
    &:after {
      color: white;
    }
  }

  &:after {
    line-height: @size;
    font-family: "FontAwesome";
    display: block;
    content: "";
    color: @darkSkyBlue;
    text-align: center;
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    border-radius: 100%;
    font-size: @size * 0.9;
    transition: all @duration @bounce, font-size 0.35s @bounce-lg;
  }

  &.is-checked:after {
    transform: scale(1);
    font-size: @size * .6;
    color: rgba(255, 255, 255, 1);
  }

  &.is-checked:hover:after {
    transform: scale(1.1);
  }
}

.check {
  float: left;
  &:after {
    content: "\f00d";
    background-color: lighten(@darkSkyBlue, 30%);
  }
  &.is-checked:after {
    content: "\f00c";
    background-color: @darkSkyBlue;
  }
}

.checkbox-text {
  margin-left: 25px;
  line-height: @size + 10px;
  cursor: pointer;
}

.cleanLink a {
  display: block;
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: inherit;
  }
}
