/* Digital Signing Modal Styling*/

.digital-signing-modal {
  .noSelect();
  min-width: 600px;
  width: 45%;
  min-height: 90vh;

  @media screen and (min-height: 1080px) {
    .digital-signing-modal__monthly {
      min-height: 480px;
    }
  
    .digital-signing-modal__weekly {
      min-height: 700px;
  
      .scroll-height {
        height: 500px;
      }
    }
  }

  

  .digital-signing-modal__required_message {
    .font12RegularSteel();
    margin-top: -15px;
  }

  label.digital-signing-modal__required {
    &:after {
      content: '*';
      margin-left: 5px;
    }
  }

  .tooltip(@textContent) {
    .font14RegularWhite();
    display: block;
    position: relative;
    opacity: 0.9;

    &:hover {
      text-decoration: none;

      &:after {
        content: @textContent;
        display: block;
        background: @charcoalGrey;
        white-space: nowrap;
        border-radius: 4px;
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
        position: fixed;
        z-index: 98;
      }
    }
  }

  .label-with-error-tooltip {
    display: flex;

    .error-tooltip {
      visibility: hidden;
    }

    .error-tooltip[data-required] {
      visibility: visible;
      .tooltip(attr(data-required));
    }

    i {
      cursor: pointer;
      color: @warmPink;
    }

    label[data-required] {
      .font14MediumWarmPink();

      &:after {
        content: "";
      }
    }
  }


  .digital-signing-modal-button-container {
    position: relative;
    .clearFix();

    .digital-signing-modal-navigation {
      height: 30px;
      line-height: 30px;
      display: flex;
      background-color: @darkSkyBlue;
      margin-top: 20px;
      margin-left: 10px;
      border-radius: 50px;
      float: right;
      padding: 0 30px;
      .font12RegularWhite();

      .fa-paper-plane,
      .fa-arrow-right {
        margin-left: 10px;
        line-height: 30px;
        font-size: 12px;
      }

      .fa-arrow-left {
        margin-right: 10px;
        line-height: 30px;
        font-size: 12px;
      }

      &:hover{
        cursor: pointer;
        background-color: darken(@darkSkyBlue, 10%);
      }
    }

    .digital-signing-modal-pages {
      padding-top:27px;
      width: 5%;
      .font12MediumSteel();
    }
  }
}

@import "DigitalSigningWizardScreen2";
@import "DigitalSigningWizardManageApprovers";
@import "DigitalSigningWizardOverview";
@import "DigitalSigningWizardMonthly";
@import "DigitalSigningWizardWeekly";
@import "DigitalSigningOverviewModal";
