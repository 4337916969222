@import "../../../../less/Fonts";

/* Disclaimer styling */

.disclaimer {
  position: absolute;
  bottom: 2px;
  left: ~"calc(100%/2)";
  .font12LightSteel();
}
